import Button from '@unreserved-frontend-v2/ui/button/button';
import { featureEnabled } from '@unreserved-frontend-v2/utils/feature-flags';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import { AssetViewerSection } from './sections/asset-viewer-section';
import { ComparablesSection } from './sections/comparables-section';
import { ContactSection } from './sections/contact-section';
import { Divider } from './sections/divider';
import { FAQSection } from './sections/faq-section';
import { HeroSection } from './sections/hero-section';
import { ModelingSection } from './sections/modeling-section';
import { RoomBuilderSection } from './sections/room-builder-section';
import { StatsSection } from './sections/stats-section/stats-section';

export const Landing = () => {
  return (
    <>
      <Header />
      <div className="m-auto max-w-[1400px] [&>*]:scroll-mt-[101px]">
        <HeroSection />
        <StatsSection />
        <ComparablesSection />
        <Divider />
        <AssetViewerSection />
        <RoomBuilderSection />
        <ModelingSection />
        <Divider />
        <FAQSection />
        <Divider />
        <ContactSection />
        <Footer />
      </div>
      {featureEnabled(process.env.NEXT_PUBLIC_IS_DEMO) ? (
        <Button className="fixed bottom-4 left-4 max-w-[200x] opacity-20 hover:opacity-100" href={'/demo'}>
          VIEW DEMO ONLY PAGE
        </Button>
      ) : null}
    </>
  );
};
