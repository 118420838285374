import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

import { FooterLogoIcon } from '@unreserved-3d/ui/icons/footer-logo-icon';

const FOOTER_LINKS = [
  { href: { pathname: '/', hash: '#hero' }, key: 'why-choose-3d' },
  { href: { pathname: '/', hash: '#asset-viewer' }, key: 'our-products' },
  { href: { pathname: '/', hash: '#room-builder' }, key: 'showcase' },
  { href: { pathname: '/', hash: '#faq' }, key: 'faq' },
  { href: '/legal/privacy-policy', key: 'privacy-policy' },
  { href: '/legal/terms-of-use', key: 'terms-and-conditions' },
];

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="text-shades-375 mt-[39px] flex flex-col justify-between px-6 pb-[75px] text-sm lg:mt-[47px] lg:h-[100px] lg:flex-row lg:px-0 lg:pb-0">
      <div className="mb-7 lg:mb-0">
        <div className="mb-[29px] lg:mb-[25px]">
          <FooterLogoIcon />
        </div>
        <span>{t('base:footer.copyright')}</span>
      </div>
      <div id="footer-right">
        <ul className="flex list-none flex-wrap gap-y-[18px] gap-x-[24px] text-right">
          {FOOTER_LINKS.map(({ href, key }) => {
            return (
              <li key={key} className="flex-shrink-0">
                <Link href={href}>{t(`base:footer.${key}`)}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </footer>
  );
};
