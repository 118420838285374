import createCache from '@emotion/cache'

const isBrowser = typeof document !== 'undefined'

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that MUI styles are loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
// This code comes from the MUI docs and is supposed to improve performance by sharing css cache between server / client
// https://github.com/mui/material-ui/tree/7b945c739335dfe741f068d1199f16d3155e3a5e/examples/nextjs-with-typescript/pages
export const createEmotionCache = () => {
  let insertionPoint

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector<HTMLMetaElement>('meta[name="emotion-insertion-point"]')
    insertionPoint = emotionInsertionPoint ?? undefined
  }

  return createCache({ key: 'mui-style', insertionPoint })
}
