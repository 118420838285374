import * as React from 'react';
import { SVGProps } from 'react';

export const FurnitureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={28} fill="none" viewBox="0 0 32 28" {...props}>
    <path
      fill="#C7C4BC"
      d="M20 2c3.313 0 6 2.688 6 6v2.344A6.053 6.053 0 0 1 28 10V8a8 8 0 0 0-8-8h-8a8 8 0 0 0-8 8v2c.7 0 1.375.119 2 .344V8c0-3.313 2.688-6 6-6h8ZM6 18v7c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1v-9c0-1.106.894-2 2-2 1.106 0 2 .894 2 2v2ZM5 28a3.008 3.008 0 0 0 2.831-2H24.17A3.008 3.008 0 0 0 27 28h2a3 3 0 0 0 3-3v-9c0-2.206-1.794-4-4-4s-4 1.794-4 4H8c0-2.206-1.794-4-4-4s-4 1.794-4 4v9a3 3 0 0 0 3 3h2Zm3-4v-6h16v6H8Zm18 1v-9c0-1.106.894-2 2-2 1.106 0 2 .894 2 2v9c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1Z"
    />
  </svg>
);
