import * as React from 'react';
import { SVGProps } from 'react';

export const WallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={29} viewBox="0 0 29 29" fill="none" {...props}>
    <path
      fill="#C7C4BC"
      d="M8.804 2.071v4.143h11.392V2.071H8.804Zm-2.072 0h-2.59a2.07 2.07 0 0 0-2.07 2.072v2.071h4.66V2.071Zm-4.66 6.215v5.178h11.392V8.286H2.071Zm0 12.428h4.66v-5.178h-4.66v5.178Zm0 2.072v2.071a2.07 2.07 0 0 0 2.07 2.072h9.322v-4.143H2.072Zm6.732-2.072h11.392v-5.178H8.804v5.178Zm13.464 0h4.66v-5.178h-4.66v5.178Zm4.66 2.072H15.537v4.143h9.321a2.07 2.07 0 0 0 2.072-2.072v-2.071Zm0-14.5H15.537v5.178h11.392V8.286Zm0-2.072V4.143a2.07 2.07 0 0 0-2.07-2.072h-2.59v4.143h4.66ZM0 4.143A4.147 4.147 0 0 1 4.143 0h20.714A4.147 4.147 0 0 1 29 4.143v20.714A4.147 4.147 0 0 1 24.857 29H4.143A4.147 4.147 0 0 1 0 24.857V4.143Z"
    />
  </svg>
);
