import * as React from 'react';
import { SVGProps } from 'react';

export const RecycleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} viewBox="0 0 23 23" fill="none" {...props}>
    <path
      fill="url(#a)"
      d="M.074 9.608a1.44 1.44 0 0 0 .908 1.82A1.441 1.441 0 0 0 2.8 10.52a4.874 4.874 0 0 1 4.622-3.333h7.794l-1.86 1.86a1.44 1.44 0 0 0 2.035 2.035l4.313-4.313a1.44 1.44 0 0 0 0-2.035L15.392.422a1.44 1.44 0 0 0-2.034 2.035l1.86 1.855H7.422A7.75 7.75 0 0 0 .074 9.608Zm22.852 3.783a1.44 1.44 0 0 0-.908-1.82 1.441 1.441 0 0 0-1.819.908 4.864 4.864 0 0 1-4.618 3.333H7.787l1.86-1.86a1.44 1.44 0 0 0-2.035-2.035L3.3 16.23a1.44 1.44 0 0 0 0 2.035l4.312 4.312a1.44 1.44 0 0 0 2.035-2.035l-1.86-1.855h7.794a7.75 7.75 0 0 0 7.35-5.296h-.005Z"
    />
    <defs>
      <linearGradient id="a" x1={11.5} x2={11.5} y1={0.001} y2={22.998} gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1AF1E" />
        <stop offset={1} stopColor="#CF9311" />
      </linearGradient>
    </defs>
  </svg>
);
