import { css } from '@emotion/react';
import { useRef } from 'react';

import { useIsChartVisible } from './use-is-chart-visible';

export type LineChartStatProps = {
  containerClassNames: string;
};

export const LineChartStat = ({ containerClassNames }: LineChartStatProps) => {
  const containerRef = useRef();
  const { isVisible } = useIsChartVisible(containerRef);

  return (
    <div ref={containerRef} className={containerClassNames}>
      {isVisible ? (
        <img
          src="/images/graph-2.png"
          css={css`
            animation-name: lineChartStatAnime;
            animation-duration: 1s;
            &.hidden {
              clip-path: inset(0% 100% 0% 0%);
            }
            &.visible {
              animation-name: lineChartStatAnime;
            }

            @keyframes lineChartStatAnime {
              from {
                clip-path: inset(0% 100% 0% 0%);
              }
              to {
                clip-path: inset(0% 0% 0% 0%);
              }
            }
          `}
          alt="line-graph"
        />
      ) : null}
    </div>
  );
};
