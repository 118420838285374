import { SVGProps } from 'react'

export function CircleCheckmarkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 66 66" className="h-[66px] w-[66px] fill-current" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 33C0 14.773 14.773 0 33 0s33 14.773 33 33-14.773 33-33 33S0 51.227 0 33Zm47.927-5.698a3.618 3.618 0 0 0 0-5.104 3.618 3.618 0 0 0-5.104 0L28.875 36.145l-5.698-5.697a3.618 3.618 0 0 0-5.104 0 3.618 3.618 0 0 0 0 5.104l8.25 8.25a3.618 3.618 0 0 0 5.104 0l16.5-16.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CircleCheckmarkIcon
