import { getSelectorsByUserAgent } from 'react-device-detect'
import { IncomingMessage } from 'http'

export function isMobile(isSsrMobile?: boolean | undefined) {
  return isSsrMobile !== undefined ? isSsrMobile : getSelectorsByUserAgent(navigator.userAgent).isMobile
}

export function isMobileRequest(req: IncomingMessage) {
  return req ? getSelectorsByUserAgent(req.headers['user-agent'] || '').isMobile : false
}
