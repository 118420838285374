import { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { SchemaFormError } from './models'

export interface SchemaFormErrorContainerProps {
  errors?: SchemaFormError[]
  showErrors?: boolean
  className?: string
  scrollToTopOnError?: boolean
}

export function SchemaFormErrorContainer({
  errors = [],
  showErrors = true,
  className = '',
  scrollToTopOnError,
}: SchemaFormErrorContainerProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (errors.length && scrollToTopOnError) {
      window.scrollTo({ top: 0 })
      setTimeout(() => {
        ref.current?.focus()
      }, 0)
    }
  }, [errors, scrollToTopOnError])

  return showErrors && errors?.length ? (
    <div
      id="schema-form-error-container"
      className={twMerge(
        'w-full rounded-[5px] border border-red-400 bg-red-50 py-[18px] px-[31px] text-left text-red-600 outline-none',
        className
      )}
      ref={ref}
      tabIndex={0}
    >
      {errors?.map((err, i) => (
        <ul
          // TODO - Change this to data-testid and make sure it indicates an error so that the meaning is clearer.
          id={`${err.invalidField}-${i}`}
          key={`${err.invalidField}-${i}`}
          className={twMerge('list-outside leading-[26px]', errors.length === 1 ? 'list-none' : 'list-disc')}
        >
          <li>{err.errorMessage}</li>
        </ul>
      ))}
    </div>
  ) : null
}

export default SchemaFormErrorContainer
