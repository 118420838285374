import { useCallback, useMemo } from 'react';
import { GraphQLClient } from 'graphql-request';
import { API_ENDPOINT } from '../constants';
import { useCreateFurnitureGeneralInquiryMutation } from '../generated/graphql/create-furniture-general-inquiry.graphql';
import { CreateFurnitureGeneralInquiryInput, FurnitureGeneralInquiry } from '../generated/graphql/types';
import { MutationErrorResponse } from '../mutations/mutation-error-response';

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

type TError = MutationErrorResponse<FurnitureGeneralInquiry>;

export function useCreateFurnitureGeneralInquiry() {
  const result = useCreateFurnitureGeneralInquiryMutation<TError>(new GraphQLClient(API_ENDPOINT));
  const { mutate, isSuccess, isError, data, error, reset } = result;

  const doMutation = useCallback(
    function (input: CreateFurnitureGeneralInquiryInput) {
      mutate({ input });
    },
    [mutate]
  );

  return useMemo(() => {
    return {
      mutate: doMutation,
      data: data?.createFurnitureGeneralInquiry,
      isError,
      isSuccess,
      error,
      reset,
    };
  }, [data?.createFurnitureGeneralInquiry, doMutation, error, isError, isSuccess, reset]);
}
