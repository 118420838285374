import { Component, ErrorInfo, ReactNode } from 'react';
import withTranslation from 'next-translate/withTranslation';
import { Translate } from 'next-translate';

import ErrorImg from '@unreserved-frontend-v2/ui/error-images/error.svg';
import NotificationWithAction from '@unreserved-frontend-v2/ui/notification-with-action';

interface Props {
  i18n: { t: Translate };
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundaryClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Datadog already intercepts errors so we shouldn't have to do anything specific here.
    // Good for debuggin'.
    //console.log(`ErrorBoundary.componentDidCatch()`, error, errorInfo)
  }

  override render() {
    const { t } = this.props.i18n;

    if (this.state.hasError) {
      document.body.classList.add('bg-shades-25');

      return (
        <NotificationWithAction
          imgSrc={ErrorImg}
          imgAlt="Error"
          imgClassName="-ml-6"
          heading={t('ui:errors.headings.oops')}
          message={t('ui:errors.messages.something-sent-wrong')}
          className="mt-20"
        />
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation(ErrorBoundaryClass);
