import { useEffect, RefObject } from 'react'

interface UseFocusTrapProps {
  ref: RefObject<HTMLElement | null>
  onEscape?: () => void
}

/**
 * Component to trap the tabbable elements inside a ref
 * Attempting to tab outside of the ref will loop back to the first tabbable element
 *
 * @param ref - The ref of the element to trap focus inside of
 * @param onEscape - Optional callback to call when the escape key is pressed
 */
export const useFocusTrap = ({ ref, onEscape }: UseFocusTrapProps): void => {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (ref.current) {
        if (event.key === 'Escape' && onEscape) {
          onEscape()
          return
        }

        const focusableElements = ref.current.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )

        if (focusableElements.length === 0) {
          return // No focusable elements inside the ref
        }

        const firstFocusableElement = focusableElements[0] as HTMLElement
        const lastFocusableElement = focusableElements[focusableElements.length - 1] as HTMLElement

        if (event.key === 'Tab' && event.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus()
            event.preventDefault()
          }
        } else if (event.key === 'Tab') {
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus()
            event.preventDefault()
          }
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [ref, onEscape])
}
