import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import Script from 'next/script';
import { twMerge } from 'tailwind-merge';
import { useEffect, useState } from 'react';

import { MouseIcon } from '@unreserved-3d/ui/icons/mouse-icon';
import { RecycleIcon } from '@unreserved-3d/ui/icons/recycle-icon';
import { HandPayIcon } from '@unreserved-3d/ui/icons/hand-pay-icon';
import { CubeIcon } from '@unreserved-3d/ui/icons/cube-icon';
import { Button3dStaged } from '@unreserved-3d/ui/button-3d-staged/button-3d-staged';
import { QRCodeModal } from '@unreserved-3d/ui/qr-code-modal/qr-code-modal';
import { isMobile } from '@unreserved-frontend-v2/utils/mobile';
import { ChevronRightIcon } from '@unreserved-3d/ui/icons/chevron-right-icon';

const POINTS = [
  { icon: MouseIcon, sectionKey: 'boost-engagement' },
  { icon: RecycleIcon, sectionKey: 'increase-sales' },
  { icon: HandPayIcon, sectionKey: 'reduce-returns' },
];

const ITEMS = [
  {
    url: '/images/box-1.png',
    animValue: -2,
    classNames: 'top-[5%] left-[5%] lg:top-[90px] lg:left-0 [&>*]:w-[88%]',
    assetId: process.env.NEXT_PUBLIC_ASSET_VIEWER_EXAMPLE_1,
  },
  {
    url: '/images/box-2.png',
    animValue: 2,
    classNames: 'top-[17%] lg:top-[130px] lg:right-[-11px] right-[5%] lg:[&>*]:mt-[29px]',
    assetId: process.env.NEXT_PUBLIC_ASSET_VIEWER_EXAMPLE_2,
  },
  {
    url: '/images/box-3.png',
    animValue: -1,
    classNames: 'bottom-[8%] right-[15%] lg:bottom-[57px] lg:right-[79px] [&>*]:w-[82%]',
    assetId: process.env.NEXT_PUBLIC_ASSET_VIEWER_EXAMPLE_3,
  },
];

const HEADER_LINKS = [
  {
    labelKey: 'base:header.learn-more',
    href: '#stats',
  },
  {
    labelKey: 'base:header.our-products',
    href: '#asset-viewer',
  },
  { labelKey: 'base:header.faq', href: '#faq' },
];

const MODEL_DETECTOR_URL = '/ar-model-detector/?model=room-builder-model-4';
const APP_ID = 'heroStandalone3dApp';
const HERO_3D_ROOM_CONTAINER_ID = 'room-builder-embedded-content-hero';

const heroRoomReadyCallbackName = 'onHeroRoomReady';

export const HeroSection = () => {
  const { t } = useTranslation();
  const [isViewingQRModal, setIsViewingQRModal] = useState(false);
  const [isRoomLoaded, setIsRoomLoaded] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);

    if (isMobile()) {
      setIsRoomLoaded(true);
    } else {
      window[heroRoomReadyCallbackName] = () => {
        setIsRoomLoaded(true);
      };
    }
  }, []);

  useEffect(() => {
    function parallax(e) {
      if (isMobile()) {
        return;
      }

      document.querySelectorAll('.item-box').forEach(function (el: HTMLAnchorElement) {
        const moving_value = Number(el.getAttribute('data-value'));
        const x = (e.clientX * moving_value) / 250;
        const y = (e.clientY * moving_value) / 250;

        el.style.transform = 'translateX(' + x + 'px) translateY(' + y + 'px)';
      });
    }

    document.addEventListener('mousemove', parallax);

    return () => {
      document.removeEventListener('mousemove', parallax);
    };
  });

  return (
    <>
      <div className="flex flex-col lg:flex-row" id="hero">
        {/* First grouping */}
        <div className="order-2 pt-3.5 lg:order-1 lg:w-1/2 lg:pt-[43px]">
          {/* Title */}
          <h1 className="px-6 text-[26px] lg:px-0 lg:text-left lg:text-[48px] lg:leading-[72px]">
            {t('landing:hero.modernize-furniture-shopping')}
            <br />
            <span
              style={{
                background: 'linear-gradient(180deg, #f1af1e 25%, #da9a0e 95%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {t('landing:hero.3d-and-ar-technology')}
            </span>
          </h1>

          {/* Mobile only nav */}
          <nav className="order-3 mt-2.5 px-6 lg:hidden">
            <ul className="flex list-none flex-col gap-x-8 lg:flex-row">
              {HEADER_LINKS.map(({ labelKey, href }) => {
                return (
                  <li key={labelKey} className="border-shades-45 h-12 border-solid [&:not(:last-child)]:border-b">
                    <Link
                      href={href}
                      className="items text-shades-900 flex h-full w-full items-center font-normal no-underline"
                    >
                      {t(labelKey)}
                      <ChevronRightIcon className="text-shades-600 absolute right-9" />
                    </Link>
                  </li>
                );
              })}
            </ul>
            <Button3dStaged href="#contact" className="mt-3 w-full justify-center font-bold capitalize">
              {t('base:header.book-a-demo')}
            </Button3dStaged>
          </nav>

          {/* Sale points */}
          <div className="order-4 px-6 pt-[35px] lg:order-2 lg:px-0">
            <ul className="flex flex-col gap-y-10">
              {POINTS.map(({ icon: Icon, sectionKey }) => {
                return (
                  <li key={sectionKey} className="flex items-start gap-x-8 lg:items-center">
                    <span className="bg-shades-40 flex h-[52px] w-[52px] flex-shrink-0 items-center justify-center rounded-full">
                      <Icon />
                    </span>
                    <span>
                      <h2 className="text-shades-900 text-lg lg:text-[20px]">
                        {t(`landing:hero.sub-sections.${sectionKey}.title`)}
                      </h2>
                      <p className="leading-8 lg:leading-[normal]">
                        {t(`landing:hero.sub-sections.${sectionKey}.description`)}
                      </p>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Hero room */}
        <div className="relative order-1 -mt-11 h-fit w-full bg-[url(/images/grid-hero-bg.png)] bg-cover lg:order-3 lg:mt-0 lg:top-0 lg:h-[622px] lg:w-1/2">
          <div className="relative my-7 lg:top-0 h-full w-full lg:bottom-6 lg:my-0">
            {isClient && isMobile() ? (
              <img src="/images/hero-room.png" className="h-auto w-full" />
            ) : (
              <div
                id={HERO_3D_ROOM_CONTAINER_ID}
                className="h-[410px] lg:h-full w-full rounded-[30px] overflow-hidden"
              />
            )}
            {isRoomLoaded
              ? ITEMS.map(({ url, animValue, classNames, assetId }) => {
                  return (
                    <div
                      key={url}
                      data-value={animValue}
                      className={twMerge(
                        'item-box', // custom class names
                        'absolute flex h-[70px] w-[70px] cursor-pointer items-center justify-center rounded-[15px] bg-white text-center duration-200 lg:h-[130px] lg:w-[130px] lg:items-start lg:rounded-[25px] lg:[&>*]:mt-[35px]',
                        'shadow-[0_0_60px_0_rgba(0,0,0,0.1)] hover:shadow-[0_0_10px_0_rgba(0,0,0,0.1)]',
                        classNames
                      )}
                      onClick={() => {
                        if (window.standaloneAssetViewer.isAppReady) {
                          window.standaloneAssetViewer.openAssetViewer(assetId);
                          const assetViewerEl = document.querySelector('#asset-viewer') as HTMLDivElement;
                          window.scrollTo({ top: assetViewerEl.offsetTop, behavior: 'instant' as ScrollBehavior });
                        }
                      }}
                    >
                      <img src={url} className="absolute h-auto w-auto object-contain" />
                    </div>
                  );
                })
              : null}
          </div>
          {isRoomLoaded ? (
            <Button3dStaged
              onClick={() => {
                if (isMobile()) {
                  window.open(`${location.origin}${MODEL_DETECTOR_URL}`, '_blank');
                } else {
                  setIsViewingQRModal(true);
                }
              }}
              className={'flex-0 absolute bottom-[2%] left-[7%] h-12 w-fit lg:left-0 lg:bottom-[55px] lg:h-[57px]'}
              variant="secondary"
            >
              <CubeIcon />
              <span className="hidden lg:block">{t('ui:view-in-ar')}</span>
              <span className="block font-bold lg:hidden">{t('ui:ar')}</span>
            </Button3dStaged>
          ) : null}
        </div>
      </div>

      {/* Modals */}
      {isViewingQRModal ? (
        <QRCodeModal url={`${location.origin}${MODEL_DETECTOR_URL}`} onClose={() => setIsViewingQRModal(false)} />
      ) : null}

      {/* Interactive Roombuilder */}
      {isClient && !isMobile() ? (
        <Script
          src={`
        ${process.env.NEXT_PUBLIC_ROOM_BUILDER_EMBED}
          ?distributionId=${process.env.NEXT_PUBLIC_ROOM_BUILDER_DISTRIBUTION_ID}
          &mode=PRESET_ROOM_DOM
          &isHeaderDisabled=true
          &isSidePanelDisabled=true
          &defaultAutoRotate=0.1
          &defaultNoEdit=true
          &domTargetId=${HERO_3D_ROOM_CONTAINER_ID}
          &defaultConfigurationId=${process.env.NEXT_PUBLIC_ROOM_BUILDER_EXAMPLE_4}
          &onReadyCallbackMethodName=${heroRoomReadyCallbackName}
          &appId=${APP_ID}
          &bgLoadColor=f8f7f4
          &defaultRoomBg=transparent
          &defaultZoomLocked=true
          &defaultGridDisabled=true
          &defaultZoom=6
        `.replace(/\s/g, '')}
          type="text/javascript"
          id={`room-builder-hero-${Date.now()}`}
          dangerouslySetInnerHTML={{ __html: `room-builder-hero-${Date.now()}` }}
        />
      ) : null}
    </>
  );
};
