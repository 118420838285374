import useTranslation from 'next-translate/useTranslation'
import { ReactNode, useRef } from 'react'
import ReactDOM from 'react-dom'
import { twMerge } from 'tailwind-merge'

import { NoOp } from '@unreserved-frontend-v2/utils/noop'

import Button from '../button/button'
import { FlexCol } from '../flex/flex-col'
import { useFocusTrap } from '../hooks/use-focus-trap/use-focus-trap'
import CircleClose from '../icons/circle-close'

export interface ModalLiteProps {
  /** Content to be rendered inside the modal window */
  children: ReactNode
  /** Callback method called when modal is closed */
  onClose?: () => void
  /** Callback method called when primary cta is clicked */
  onPrimaryCta?: () => void
  /** Modal title */
  title?: string
  /** classes for the entire modal container */
  containerClassName?: string
  /** classes for the modal window  */
  windowClassName?: string
  /** classes for the header  */
  headerClassName?: string
  /** classes for the footer  */
  footerClassName?: string
  /** classes for the full screen background  */
  backgroundClassName?: string
  /** if true, will render a full screen background behind the modal */
  displayBackground?: boolean
  /** text to overwrite the cancel button label*/
  cancelButtonLabel?: string
  /** text to overwrite the primary cta button label*/
  ctaButtonLabel?: string | ReactNode
  /** component to entirely override the header of the modal */
  header?: ReactNode
  /** component to entirely override the footer of the modal */
  footer?: ReactNode
  /** If true, modal will be rendered in a portal */
  isPortal?: boolean
  /** if provided, modal will portal inside the parent element */
  parentElement?: HTMLElement
}

/**
 * Basic modal that leaves most of the implementation up to the consumer.
 * Open / Closed state is managed entirely by the consumer
 * Focus trapping and 'esc' to close are managed internally for accessibility
 *
 * Note: this is the modal that should be used for all new modal implementations, old implementations
 * should be gradually moved to this implementation on an as-needed basis
 */
export const ModalLite = ({
  onClose = NoOp,
  onPrimaryCta,
  children,
  header,
  footer,
  containerClassName,
  windowClassName,
  backgroundClassName,
  headerClassName,
  footerClassName,
  title,
  cancelButtonLabel,
  ctaButtonLabel,
  isPortal = true,
  parentElement,
}: ModalLiteProps) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  useFocusTrap({ ref: modalRef, onEscape: onClose })

  const Modal = (
    <div
      ref={modalRef}
      className={twMerge(
        'fixed top-0 left-0 z-[1000001] flex h-screen w-screen items-center justify-center',
        containerClassName
      )}
    >
      <div
        className={twMerge('absolute h-full w-full bg-[rgba(0,0,0,0.5)]', backgroundClassName)}
        onClick={() => onClose()}
      />

      <FlexCol
        className={twMerge(
          'relative w-full max-w-[864px] rounded-[5px] border border-shades-200 bg-white drop-shadow',
          windowClassName
        )}
      >
        {header !== undefined ? (
          header
        ) : (
          <div
            className={twMerge(
              'flex items-center justify-between border-b border-shades-200 py-2 pl-8 pr-5',
              headerClassName
            )}
          >
            <h2 className="text-lg font-bold">{title}</h2>
            <Button variant="unstyled" onClick={() => onClose()}>
              <CircleClose className="text-shades-200 hover:text-shades-600" />
            </Button>
          </div>
        )}

        {children}

        {footer !== undefined ? (
          footer
        ) : (
          <div className={twMerge('flex justify-between border-t border-shades-200 py-4 px-8', footerClassName)}>
            <Button variant="ghost" size="small" onClick={onClose}>
              {cancelButtonLabel || t('ui:modal-lite.cancel')}
            </Button>
            <Button variant="primary" size="small" onClick={onPrimaryCta}>
              {ctaButtonLabel || t('ui:modal-lite.save')}
            </Button>
          </div>
        )}
      </FlexCol>
    </div>
  )

  return !isPortal ? Modal : ReactDOM.createPortal(Modal, parentElement || document.body)
}
