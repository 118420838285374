import * as React from 'react';
import { SVGProps } from 'react';

export const RealTimeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={28} fill="none" viewBox="0 0 36 28" {...props}>
    <path
      fill="#C7C4BC"
      d="M7.148 6.344c2.63-2.44 6.125-4.392 10.42-4.392 4.294 0 7.79 1.952 10.42 4.392 2.629 2.44 4.386 5.369 5.197 7.321-.812 1.952-2.568 4.88-5.198 7.32-2.629 2.44-6.124 4.393-10.42 4.393-4.294 0-7.79-1.952-10.419-4.393-2.63-2.44-4.386-5.368-5.197-7.32.811-1.952 2.568-4.88 5.197-7.32ZM17.568 0c-4.93 0-8.876 2.245-11.75 4.917-2.855 2.648-4.764 5.82-5.667 7.998a1.94 1.94 0 0 0 0 1.5c.903 2.178 2.812 5.35 5.667 7.998 2.874 2.672 6.82 4.917 11.75 4.917 4.929 0 8.876-2.245 11.75-4.917 2.854-2.654 4.764-5.82 5.673-7.998a1.94 1.94 0 0 0 0-1.5c-.91-2.178-2.819-5.35-5.674-7.998C26.444 2.245 22.497 0 17.567 0ZM11.71 13.665a5.856 5.856 0 1 1 11.713 0 5.856 5.856 0 0 1-11.713 0Zm13.665 0a7.809 7.809 0 1 0-15.617 0 7.809 7.809 0 0 0 15.617 0Z"
    />
  </svg>
);
