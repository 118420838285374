import useTranslation from 'next-translate/useTranslation';
import { useLayoutEffect, useRef } from 'react';
import { toCanvas } from 'qrcode';

import Button from '@unreserved-frontend-v2/ui/button/button';
import { ModalLite, ModalLiteProps } from '@unreserved-frontend-v2/ui/modal-lite/modal-lite';
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col';

export type QRCodeModalProps = Pick<ModalLiteProps, 'onClose'> & {
  url: string;
};

export const QRCodeModal = ({ onClose, url }: QRCodeModalProps) => {
  const { t } = useTranslation();
  const qrCanvas = useRef<HTMLCanvasElement>(null);

  useLayoutEffect(() => {
    toCanvas(qrCanvas.current, url, { width: 108, margin: 0 });
  }, [url]);

  return (
    <ModalLite
      windowClassName="w-[300px]"
      footer={
        <Button
          variant="unstyled"
          className="items center border-shades-200 text-shades-600 h-[42px] rounded-none border-t py-0"
          onClick={onClose}
        >
          {t('ui:qr-modal.close')}
        </Button>
      }
      headerClassName="border-none p-0 [&>button]:pb-0"
      onClose={onClose}
    >
      <FlexCol className="items-center gap-[25px] px-8 pb-6 text-center">
        <canvas ref={qrCanvas} />
        <div>{t('ui:qr-modal.cta')}</div>
      </FlexCol>
    </ModalLite>
  );
};
