import * as React from 'react';
import { SVGProps } from 'react';

export const MeasurementIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width={34} height={34} fill="none" {...props}>
    <path
      fill="#C7C4BC"
      d="M2.864 26.323a2.22 2.22 0 0 1 0-3.138l2.729-2.729 2.867 2.867c.43.43 1.136.43 1.565 0 .43-.429.43-1.135 0-1.565l-2.867-2.867 2.867-2.868 2.868 2.868c.43.43 1.136.43 1.565 0 .43-.43.43-1.136 0-1.565l-2.867-2.868 2.867-2.867 2.868 2.867c.429.43 1.135.43 1.565 0 .43-.43.43-1.136 0-1.565l-2.868-2.868 2.868-2.867 2.867 2.867c.43.43 1.136.43 1.566 0 .429-.429.429-1.135 0-1.565l-2.868-2.867 2.729-2.73a2.22 2.22 0 0 1 3.138 0l4.813 4.815a2.22 2.22 0 0 1 0 3.137L10.808 31.136a2.22 2.22 0 0 1-3.137 0l-4.807-4.813ZM6.112 32.7a4.431 4.431 0 0 0 6.268 0l20.321-20.32a4.431 4.431 0 0 0 0-6.269L27.895 1.3a4.431 4.431 0 0 0-6.268 0L1.299 21.619a4.431 4.431 0 0 0 0 6.269L6.112 32.7Z"
    />
  </svg>
);
