import { css } from '@emotion/react';

import { useRef } from 'react';
import { useIsChartVisible } from './use-is-chart-visible';

export type HalfCircleStatProps = {
  containerClassNames: string;
};

export const HalfCircleStat = ({ containerClassNames }: HalfCircleStatProps) => {
  const containerRef = useRef();
  const { isVisible } = useIsChartVisible(containerRef);

  return (
    <div ref={containerRef} className={containerClassNames}>
      {isVisible ? (
        <img
          src="/images/graph-5.png"
          css={css`
            animation-duration: 0.35s;
            animation-timing-function: ease-out;
            animation-name: halfCircleStatAnim;

            @keyframes halfCircleStatAnim {
              0% {
                clip-path: polygon(50% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 100%);
              }
              25% {
                clip-path: polygon(50% 100%, 0% 100%, 0% 0%, 0% 0%, 0% 0%, 0% 0%);
              }
              50% {
                clip-path: polygon(50% 100%, 0% 100%, 0% 0%, 50% 0%, 50% 0%, 50% 0%);
              }
              75% {
                clip-path: polygon(50% 100%, 0% 100%, 0% 0%, 50% 0%, 100% 0%, 100% 0%);
              }
              100% {
                clip-path: polygon(50% 100%, 0% 100%, 0% 0%, 50% 0%, 100% 0%, 100% 100%);
              }
            }
          `}
          alt="half-circle-graph"
        />
      ) : null}
    </div>
  );
};
