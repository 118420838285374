import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { twMerge } from 'tailwind-merge';
import { BarChartStat } from './bar-chart-stat';
import { CircleStat } from './circle-stat';
import { HalfCircleStat } from './half-circle-stat';
import { LineChartStat } from './line-chart-stat';
import { PieChartStat } from './pie-chart-stat';

const STATS = [
  {
    descriptionKey: 'consumers-reported',
    infoGraphClasses: 'h-[118px] lg:w-[190px]',
    sourceKey: 'source-marxent-labs',
    classNames: 'lg:w-[49%]',
    chartClass: CircleStat,
  },
  {
    descriptionKey: 'the-use-of-3d',
    infoGraphClasses: 'h-[133px] lg:w-[240px]',
    sourceKey: 'source-marxent-labs',
    classNames: 'lg:w-[49%]',
    chartClass: LineChartStat,
  },
  {
    descriptionKey: 'product-page-visitors',
    infoGraphClasses: 'h-[133px] lg:w-[150px]',
    sourceKey: 'source-marxent-labs',
    classNames: 'lg:w-[32%]',
    chartClass: BarChartStat,
  },
  {
    descriptionKey: 'shoppers-say-they-prefer',
    infoGraphClasses: 'h-[135px] lg:w-[190px]',
    sourceKey: 'source-shopify',
    classNames: 'lg:w-[32%]',
    chartClass: PieChartStat,
  },
  {
    descriptionKey: 'shoppers-say-that-ar',
    infoGraphClasses: 'h-[73px] lg:w-[200px]',
    sourceKey: 'source-shopify',
    classNames: 'lg:w-[32%]',
    chartClass: HalfCircleStat,
  },
];

export const StatsSection = () => {
  const { t } = useTranslation();

  return (
    <div
      className="mb-1 mt-[47px] flex flex-col flex-wrap justify-between px-6 lg:mb-[30px] lg:mt-[55px] lg:flex-row lg:px-0"
      id="stats"
    >
      {STATS.map(({ descriptionKey, sourceKey, classNames, infoGraphClasses, chartClass: ChartClass }) => {
        return (
          <div
            key={descriptionKey}
            className={twMerge(
              'bg-shades-45 relative mb-6 flex h-fit flex-col items-center overflow-hidden rounded-[35px] px-6 leading-8 lg:mb-[2%] lg:table lg:h-[210px] lg:items-start lg:px-0 [&>*]:table-cell',
              classNames
            )}
          >
            <ChartClass
              containerClassNames={twMerge(
                '[&]:flex items-center justify-center lg:flex-shrink-1 mt-8 mb-7 w-full flex-shrink-0 bg-auto bg-center bg-no-repeat lg:mt-0 lg:h-full',
                infoGraphClasses
              )}
            />
            <div className="h-full pb-3.5 text-center align-middle lg:pb-0 lg:pr-[30px] lg:text-left">
              <Trans i18nKey={`landing:stats.${descriptionKey}`} components={[<strong key="percent" />]} />
            </div>
            <span className="text-shades-375 pb-6 text-sm lg:absolute lg:bottom-[17px] lg:right-[30px] lg:pb-0">
              {t(`landing:stats.${sourceKey}`)}
            </span>
          </div>
        );
      })}
    </div>
  );
};
