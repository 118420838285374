import * as React from 'react';
import { SVGProps } from 'react';

export const CheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" viewBox="0 0 27 27" {...props}>
    <path
      fill="#43AA8B"
      d="M13.5 27a13.5 13.5 0 1 0 0-27 13.5 13.5 0 0 0 0 27Zm5.959-15.979-6.75 6.75a1.26 1.26 0 0 1-1.788 0l-3.375-3.375a1.265 1.265 0 0 1 1.788-1.788l2.479 2.48 5.853-5.86a1.265 1.265 0 0 1 1.788 1.788l.005.005Z"
    />
  </svg>
);
