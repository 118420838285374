import * as React from 'react';
import { SVGProps } from 'react';

export const AugmentedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
    <path
      fill="#C7C4BC"
      d="M4.286 27.857a2.14 2.14 0 0 1-2.143-2.143V4.286a2.14 2.14 0 0 1 2.143-2.143h21.428a2.14 2.14 0 0 1 2.143 2.143v21.428a2.14 2.14 0 0 1-2.143 2.143H4.286ZM0 25.714A4.29 4.29 0 0 0 4.286 30h21.428A4.29 4.29 0 0 0 30 25.714V4.286A4.29 4.29 0 0 0 25.714 0H4.286A4.29 4.29 0 0 0 0 4.286v21.428Zm15.757-2.457 6.428-6.429a1.075 1.075 0 0 0 0-1.513 1.075 1.075 0 0 0-1.513 0l-4.6 4.6V7.5c0-.59-.483-1.071-1.072-1.071-.59 0-1.071.482-1.071 1.071v12.415l-4.6-4.6a1.075 1.075 0 0 0-1.514 0 1.075 1.075 0 0 0 0 1.513l6.428 6.429a1.075 1.075 0 0 0 1.514 0Z"
    />
  </svg>
);
