import { ErrorCodes } from '@unreserved-frontend-v2/utils/error-codes'
import { ClientError } from 'graphql-request'
import { GraphQlErrorType } from '../generated/graphql/types'

export function getErrorCode(error: ClientError): number | undefined {
  if (!error?.response) {
    return undefined
  }

  const { status, errors } = error.response

  // Check for high level status errors first.
  if (status === ErrorCodes.Four01) {
    return ErrorCodes.Four01
  }

  // Now check for custom errors that we want to handle.
  // Note that we only handle the first error. To be revisited if we need to.
  if (errors?.length) {
    switch (errors[0].extensions?.['classification']) {
      case GraphQlErrorType.EntityNotFound:
        return ErrorCodes.Four04

      default:
        return undefined
    }
  }

  return undefined
}
