import * as React from 'react';
import { SVGProps } from 'react';

export const CNIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={20} fill="none" viewBox="0 0 10 20" {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m7.803 7.1.363 1.563 1.191 2.062-.1.737H.124L0 10.725l1.415-2.062L1.76 7.1h2.074V3.47h.525V0h.797v3.471h.504v3.63h2.143Zm-4.58 7.807H.688l-.373-2.165h8.77l-.285 2.165h-2.74c.028.327.062.806.103 1.374.076 1.065.175 2.443.294 3.719H2.965a143.9 143.9 0 0 0 .257-5.093Z"
      clipRule="evenodd"
    />
  </svg>
);
