import debounce from 'lodash/debounce';
import { RefObject, useEffect } from 'react';

/** Helper hook that disables interactions on an element when the document is scrolling */
export const useScrollingDisabledElement = (ref: RefObject<HTMLElement>, time: number = 500) => {
  // Interaction disabling for when scrolling
  useEffect(() => {
    const onScrollComplete = debounce(() => {
      ref?.current?.classList.toggle('pointer-events-none', false);
    }, time);

    const onScroll = () => {
      ref?.current?.classList.toggle('pointer-events-none', true);
      onScrollComplete();
    };
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);
};
