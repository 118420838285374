import React from 'react'
import { useState, useRef, useEffect, CSSProperties } from 'react'
import Skeleton from '../skeleton/skeleton'
import { twMerge } from 'tailwind-merge'

export interface ImageWithShimmerProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  srcset?: string
  alt?: string
  className?: string
  imgClassName?: string
  id?: string
  style?: CSSProperties
  removeSkeletonOnLoad?: boolean
}

export function ImageWithShimmer({
  src,
  className,
  imgClassName,
  srcset,
  id,
  style = {},
  removeSkeletonOnLoad,
  ...restOfProps
}: ImageWithShimmerProps) {
  const [fadeIn, setFadeIn] = useState(true)
  const imgRef: React.RefObject<HTMLImageElement> = useRef(null)

  useEffect(() => {
    if (imgRef.current && !imgRef.current.complete) {
      setFadeIn(false)
      imgRef.current.onload = () => {
        setFadeIn(true)
      }
    }
  }, [])

  return (
    <div className={twMerge(`relative h-full w-full`, !fadeIn ? 'noblur' : '', className ?? '')} style={style}>
      {/* Only render the Skeleton if fadeIn is false and removeSkeletonOnLoad is not true */}
      {!fadeIn && !removeSkeletonOnLoad ? <Skeleton className="absolute h-full w-full" /> : null}

      <img
        id={id}
        ref={imgRef}
        className={twMerge(
          `absolute h-full w-full object-cover opacity-0 ${fadeIn ? 'opacity-100 transition-all duration-200' : ''} ${
            imgClassName ?? ''
          }`
        )}
        alt={src}
        src={src}
        srcSet={srcset}
        loading="lazy"
        {...restOfProps}
      />
    </div>
  )
}

export default ImageWithShimmer
