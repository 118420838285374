import { twMerge } from 'tailwind-merge';

import Button, { ButtonProps } from '@unreserved-frontend-v2/ui/button/button';

type Button3dStagedVariants = 'primary' | 'secondary';

export type Button3dStagedProps = Omit<ButtonProps, 'variant'> & {
  variant?: Button3dStagedVariants;
};

const BASE_CLASSES =
  'flex items-center w-fit text-base font-normal normal-case rounded-[40px] tracking-normal no-underline transition-colors duration-150';

const VARIANT_CLASSES: Record<Button3dStagedVariants, string> = {
  primary: 'bg-shades-900 h-[51px] py-[15px] px-[20px] text-white hover:bg-orange-300',
  secondary:
    'bg-white gap-x-[15px] h-auto border border-solid border-shades-50 text-shades-900 py-[15px] pr-[23px] pl-[20px] hover:bg-white-25',
};

export const Button3dStaged = ({ variant = 'primary', ...props }: Button3dStagedProps) => {
  return (
    <Button
      variant="unstyled"
      {...props}
      className={twMerge(BASE_CLASSES, VARIANT_CLASSES[variant], props.className)}
    />
  );
};
