import * as React from 'react';
import { SVGProps } from 'react';

export const VisualizationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="#C7C4BC"
      d="M14.933 1.95a.914.914 0 0 0-.629 0L2.518 6.269l12.1 4.756 12.1-4.75L14.934 1.95ZM1.827 21.972c0 .383.24.726.6.857l11.278 4.134V12.623L1.827 7.958v14.013Zm13.705 4.991 11.278-4.134c.36-.131.6-.474.6-.857V7.958l-11.878 4.665v14.339ZM13.676.232a2.741 2.741 0 0 1 1.885 0l11.877 4.357a2.74 2.74 0 0 1 1.799 2.575v14.807c0 1.148-.72 2.176-1.799 2.576l-11.877 4.35a2.741 2.741 0 0 1-1.885 0L1.8 24.542A2.738 2.738 0 0 1 0 21.97V7.164C0 6.017.72 4.99 1.799 4.59L13.676.232Z"
    />
  </svg>
);
