import { twMerge } from 'tailwind-merge'

export interface FlexRowProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string
  children: React.ReactNode
}

export function FlexRow({ className = '', children, ...rest }: FlexRowProps) {
  return (
    <div className={twMerge('flex', className)} {...rest}>
      {children}
    </div>
  )
}
