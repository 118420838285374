import * as React from 'react';
import { SVGProps } from 'react';

export const CheckedNoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" viewBox="0 0 27 27" {...props}>
    <path
      fill="#E5E2D9"
      d="M13.5 27a13.5 13.5 0 1 0 0-27 13.5 13.5 0 0 0 0 27ZM9.229 9.229a1.26 1.26 0 0 1 1.787 0l2.479 2.478 2.478-2.478a1.265 1.265 0 0 1 1.788 1.788l-2.479 2.478 2.479 2.478a1.265 1.265 0 0 1-1.788 1.788l-2.478-2.479-2.479 2.479a1.265 1.265 0 0 1-1.788-1.788l2.479-2.478-2.478-2.479a1.26 1.26 0 0 1 0-1.787Z"
    />
  </svg>
);
