import { SchemaFormError } from '@unreserved-frontend-v2/ui/schema-form/models'
import { MutationErrorResponse } from '@unreserved-frontend-v2/utils/api/mutation-error-response'

export function mapMutationErrorsForUI(mutationError: MutationErrorResponse | null): SchemaFormError[] {
  if (!mutationError) {
    return []
  }

  return mutationError.response.errors.map((err, i) => {
    return err.extensions
      ? {
          errorMessage: err.message,
          invalidField: err.extensions.invalidField,
        }
      : {
          // This is the case when an unhandled error is returned from the call to GraphQL. We simply use
          // the index of the message as a field value because the messages won't be associated with a
          // particular field id.
          invalidField: i.toString(),
          errorMessage: err.message,
        }
  })
}
