import * as React from 'react';
import { SVGProps } from 'react';

export const DragIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={29} fill="none" viewBox="0 0 29 29" {...props}>
    <path
      fill="#C7C4BC"
      d="m15.168 18.742 5.438-5.89a.905.905 0 1 0-1.331-1.23l-3.869 4.186V.906A.909.909 0 0 0 14.5 0a.909.909 0 0 0-.906.906v14.902L9.73 11.623A.905.905 0 1 0 8.4 12.852l5.437 5.89a.9.9 0 0 0 .669.29.93.93 0 0 0 .668-.29h-.006ZM3.625 1.812C1.625 1.813 0 3.438 0 5.438v19.938C0 27.375 1.626 29 3.625 29h21.75c2 0 3.625-1.626 3.625-3.625V5.437c0-1.999-1.626-3.625-3.625-3.625h-2.719a.909.909 0 0 0-.906.907c0 .498.408.906.906.906h2.719a1.81 1.81 0 0 1 1.813 1.813v19.937a1.81 1.81 0 0 1-1.813 1.813H3.625a1.81 1.81 0 0 1-1.813-1.813V5.437a1.81 1.81 0 0 1 1.813-1.812h2.719a.909.909 0 0 0 .906-.906.909.909 0 0 0-.906-.906H3.625Z"
    />
  </svg>
);
