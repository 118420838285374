
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col';
import { Spacer } from '@unreserved-frontend-v2/ui/spacer/spacer';
export interface AppTemplateProps {
    className?: string;
    children?: ReactNode;
}
/**
 * Represents the template for a page (both mobile & desktop). The passed in children will be rendered in a column
 * that centers the content both horizontally and vertically inside of it. The template is designed to take up
 * the entire viewport and includes a <Spacer /> that will take up any remaining vertical space after the children.
 */
export function PageTemplate({ className = '', children }: AppTemplateProps) {
    return (<FlexCol className={twMerge('relative h-screen', className)}>
      <FlexCol className="bg-shades-25 flex-grow items-center justify-center">
        {children}
        <Spacer />
      </FlexCol>
    </FlexCol>);
}
export default PageTemplate;

    async function __Next_Translate__getStaticProps__18ec8cf5539__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//page-template/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18ec8cf5539__ as getStaticProps }
  