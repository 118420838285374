import useTranslation from 'next-translate/useTranslation';

import ImageWithShimmer from '@unreserved-frontend-v2/ui/image-with-shimmer/image-with-shimmer';
import { Button3dStaged } from '@unreserved-3d/ui/button-3d-staged/button-3d-staged';
import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row';
import { twMerge } from 'tailwind-merge';

const MODELS = [
  'bg-[url(/images/modeling-1.png)]',
  'bg-[url(/images/modeling-2.png)]',
  'bg-[url(/images/modeling-3.png)]',
  'bg-[url(/images/modeling-4.png)]',
  'bg-[url(/images/modeling-5.png)]',
];

export const ModelingSection = () => {
  const { t } = useTranslation();
  const [primaryImage, ...gallery] = MODELS;

  return (
    <div className="px-6 lg:mb-[81px] lg:px-0">
      {/* Title */}
      <FlexRow className="w-full flex-wrap justify-between">
        <h3 className="text-[26px] lg:text-[34px]">{t('landing:modeling.title')}</h3>
        <Button3dStaged href="#contact" className="hidden lg:flex">
          {t('base:header.book-a-demo')}
        </Button3dStaged>
        <p className="mt-[30px] w-full leading-8">{t('landing:modeling.description')}</p>
      </FlexRow>

      {/* Gallery */}
      <div className="mt-10 flex flex-col gap-y-2.5 lg:mt-[50px] lg:h-[656px] lg:flex-row lg:gap-x-[20px]">
        {/* Gallery Hero */}
        <div className="bg-shades-45 h-[336px] w-full overflow-hidden rounded-[35px] bg-[url(/images/grid.png)] px-10 lg:h-full lg:w-1/2 lg:px-0">
          <div
            className={twMerge('h-full w-full bg-contain bg-center bg-no-repeat lg:bg-auto', primaryImage)}
            key={primaryImage}
          />
        </div>

        {/* Gallery thumbs */}
        <div className="grid h-auto w-full grid-cols-2 flex-wrap gap-2.5 lg:w-1/2 lg:gap-[20px]">
          {gallery.map((src) => {
            return (
              <div
                key={src}
                className="bg-shades-45 grid-span-1 h-[160px] w-full overflow-hidden rounded-[35px] bg-[url(/images/grid.png)] px-5 lg:h-full lg:px-0"
              >
                <div className={twMerge('h-full w-full bg-contain bg-center bg-no-repeat lg:bg-auto', src)} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
