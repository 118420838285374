import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { Button3dStaged } from '@unreserved-3d/ui/button-3d-staged/button-3d-staged';
import { useLayoutEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row';

const HEADER_BREAKPOINT = 457;

const HEADER_LINKS = [
  {
    labelKey: 'base:header.why-choose-3d',
    href: { pathname: '/', hash: '#stats' },
  },
  {
    labelKey: 'base:header.our-products',
    href: { pathname: '/', hash: '#asset-viewer' },
  },
  { labelKey: 'base:header.faq', href: { pathname: '/', hash: '#faq' } },
];

export const Header = () => {
  const { t } = useTranslation();
  const [isUnderFold, setIsUnderFold] = useState(false);
  const containerRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const onDocumentScroll = () => {
      if (!isUnderFold) {
        setIsUnderFold(document.scrollingElement.scrollTop >= HEADER_BREAKPOINT);
      } else {
        setIsUnderFold(document.scrollingElement.scrollTop >= containerRef.current.offsetHeight / 2);
      }
    };
    document.addEventListener('scroll', onDocumentScroll);

    onDocumentScroll();
    return () => {
      document.removeEventListener('scroll', onDocumentScroll);
    };
  }, [isUnderFold]);

  return (
    <div
      className={twMerge(
        'lg:bg-white-50 relative z-[99] flex w-full translate-y-0 justify-center text-center transition-all duration-200 ease-out',
        isUnderFold ? 'lg:sticky lg:-top-[101px] lg:translate-y-[101px] ' : ''
      )}
      ref={containerRef}
    >
      <header className="flex w-full max-w-[1400px] items-center justify-center py-[25px] text-center lg:justify-between">
        <nav className="hidden lg:block">
          <ul className="flex list-none flex-col gap-x-8 lg:flex-row">
            {HEADER_LINKS.map(({ labelKey, href }) => {
              return (
                <li key={labelKey}>
                  <Link href={href} className="text-shades-900 font-normal no-underline">
                    {t(labelKey)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <Link href="/">
          <img alt="3DStaged" src="/images/logo.png" className="h-[30px] lg:h-[42px] lg:pr-[57px]" />
        </Link>
        <FlexRow className="hidden items-baseline gap-x-8 lg:flex">
          <Link href="#contact">{t('base:header.contact-us')}</Link>
          <Button3dStaged href="#contact" className="flex-0">
            {t('base:header.book-a-demo')}
          </Button3dStaged>
        </FlexRow>
      </header>
    </div>
  );
};
