import useTranslation from 'next-translate/useTranslation';
import Script from 'next/script';
import { twMerge } from 'tailwind-merge';
import { useCallback, useEffect, useRef, useState } from 'react';

import ImageWithShimmer from '@unreserved-frontend-v2/ui/image-with-shimmer/image-with-shimmer';
import Button from '@unreserved-frontend-v2/ui/button/button';
import { Button3dStaged } from '@unreserved-3d/ui/button-3d-staged/button-3d-staged';
import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row';
import { DegreeViewIcon } from '@unreserved-3d/ui/icons/degree-view-icon';
import { AugmentedIcon } from '@unreserved-3d/ui/icons/augmented-icon';
import { MeasurementIcon } from '@unreserved-3d/ui/icons/measurement-icon';
import { useScrollingDisabledElement } from '@unreserved-3d/ui/hooks/use-scrolling-disabled-element';

const ITEMS = [
  {
    thumbnail: '/images/box-1.png',
    id: process.env.NEXT_PUBLIC_ASSET_VIEWER_EXAMPLE_1,
    classNames: 'lg:w-[88%] lg:mt-[35px]',
  },
  {
    thumbnail: '/images/box-2.png',
    id: process.env.NEXT_PUBLIC_ASSET_VIEWER_EXAMPLE_2,
    classNames: 'lg:w-full lg:mt-[29px]',
  },
  {
    thumbnail: '/images/box-3.png',
    id: process.env.NEXT_PUBLIC_ASSET_VIEWER_EXAMPLE_3,
    classNames: 'lg:w-[82%] lg:mt-[35px]',
  },
  {
    thumbnail: '/images/box-4.png',
    id: process.env.NEXT_PUBLIC_ASSET_VIEWER_EXAMPLE_4,
    classNames: 'lg:w-[90%] lg:mt-[48px]',
  },
];
const DEFAULT_ASSET_ID = ITEMS[0].id;

const POINTS = [
  { icon: DegreeViewIcon, pointKey: '360-degree' },
  { icon: AugmentedIcon, pointKey: 'augmented-reality' },
  { icon: MeasurementIcon, pointKey: 'measurement-tools' },
];

const CONTAINER_ID = 'asset-viewer-embedded-content';

export const AssetViewerSection = () => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState('');
  const assetViewerContainerRef = useRef<HTMLDivElement>();

  useScrollingDisabledElement(assetViewerContainerRef);

  useEffect(() => {
    window.onAssetViewerReady = () => {
      setSelectedId(DEFAULT_ASSET_ID);
    };

    window.onAssetViewerOpen = (id: string) => {
      setSelectedId(id);
    };

    return () => {
      window.onAssetViewerReady = undefined;
      window.onAssetViewerOpen = undefined;
    };
  }, [selectedId]);

  const onOpenAssetViewer = useCallback(
    (id: string) => () => {
      window.standaloneAssetViewer.openAssetViewer(id);
    },
    []
  );

  return (
    <>
      <div className="mb-[73px] px-6 pt-2.5 lg:mb-[144px] lg:px-0 lg:pt-0" id="asset-viewer">
        {/* Title */}
        <FlexRow className="w-full flex-wrap justify-between">
          <h3 className="text-[26px] lg:text-[34px]">{t('landing:asset-viewer.title')}</h3>
          <Button3dStaged href="#contact" className="hidden lg:flex">
            {t('base:header.book-a-demo')}
          </Button3dStaged>
          <p className="mt-[30px] w-full leading-8">{t('landing:asset-viewer.description')}</p>
        </FlexRow>

        {/* AssetViewer & gallery */}
        <div className="mt-10 flex w-full flex-col gap-y-3 lg:mt-[50px] lg:h-[600px] lg:flex-row lg:gap-x-[25px]">
          {/* Asset Viewer */}
          <div
            ref={assetViewerContainerRef}
            id={CONTAINER_ID}
            className="h-[418px] w-full overflow-hidden rounded-[40px] lg:h-full lg:w-[1245px] [&>iframe]:rounded-[40px]"
          />
          {/* Gallery */}
          <div className="flex h-full flex-row justify-start gap-x-3 lg:w-[130px] lg:flex-col lg:gap-y-[26px]">
            {ITEMS.map(({ id, thumbnail, classNames }) => {
              const isSelected = id === selectedId;

              return (
                <Button
                  variant="unstyled"
                  className={twMerge(
                    'relative flex aspect-square h-auto w-[78px] grow items-center justify-center rounded-[14px] bg-white p-0 px-1 lg:h-[130px] lg:w-full lg:items-start lg:rounded-[25px] lg:px-0',
                    !selectedId ? 'pointer-events-none' : ''
                  )}
                  onClick={onOpenAssetViewer(id)}
                  key={id}
                >
                  <ImageWithShimmer
                    src={thumbnail}
                    className={twMerge('m-auto flex items-center lg:items-start', classNames)}
                    imgClassName="object-contain h-auto w-auto"
                  />
                  <div
                    className={twMerge(
                      'border-primary absolute -top-1 -bottom-1 -left-1 -right-1 rounded-[25px] border-solid transition duration-150',
                      isSelected ? 'border-2' : ''
                    )}
                  />
                </Button>
              );
            })}
          </div>
        </div>

        {/* Points */}
        <div className="mt-10 flex flex-col flex-wrap gap-x-[4%] lg:mt-[45px] lg:flex-row">
          {POINTS.map(({ pointKey, icon: Icon }) => {
            return (
              <div key={pointKey} className="w-full lg:w-[30%] [&:not(:last-child)]:pb-9 lg:[&:not(:last-child)]:pb-0">
                <div className="mb-[24px] h-[30px]">
                  <Icon />
                </div>
                <h4 className="text-lg lg:text-[18px]">{t(`landing:asset-viewer.sub-sections.${pointKey}.title`)}</h4>
                <p className="leading-8">{t(`landing:asset-viewer.sub-sections.${pointKey}.description`)}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Script
        defer={true}
        src={`${process.env.NEXT_PUBLIC_ASSET_VIEWER_EMBED}?mode=DOM&defaultAssetId=${DEFAULT_ASSET_ID}`}
        type="text/javascript"
        id={`asset-viewer-embed-script-${Date.now()}`}
        dangerouslySetInnerHTML={{ __html: `asset-viewer-embed-script-${Date.now()}` }}
      />
    </>
  );
};
