import useTranslation from 'next-translate/useTranslation';
import Script from 'next/script';
import { twMerge } from 'tailwind-merge';
import { useCallback, useEffect, useRef, useState } from 'react';

import Button from '@unreserved-frontend-v2/ui/button/button';
import ImageWithShimmer from '@unreserved-frontend-v2/ui/image-with-shimmer/image-with-shimmer';
import { Button3dStaged } from '@unreserved-3d/ui/button-3d-staged/button-3d-staged';
import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row';
import { DragIcon } from '@unreserved-3d/ui/icons/drag-icon';
import { FurnitureIcon } from '@unreserved-3d/ui/icons/furniture-icon';
import { CustomizableIcon } from '@unreserved-3d/ui/icons/customizable-icon';
import { WallIcon } from '@unreserved-3d/ui/icons/wall-icon';
import { VisualizationIcon } from '@unreserved-3d/ui/icons/visualization-icon';
import { RealTimeIcon } from '@unreserved-3d/ui/icons/real-time-icon';
import { EditIcon } from '@unreserved-3d/ui/icons/edit-icon';
import { CubeIcon } from '@unreserved-3d/ui/icons/cube-icon';
import { QRCodeModal } from '@unreserved-3d/ui/qr-code-modal/qr-code-modal';
import { useScrollingDisabledElement } from '@unreserved-3d/ui/hooks/use-scrolling-disabled-element';

const MODEL_DETECTOR_URL = '/ar-model-detector';
type RoomType = {
  thumbnail: string;
  model: string;
  id: string;
};
const ROOMS: RoomType[] = [
  {
    thumbnail: '/images/planner-thumb1.jpg',
    model: 'room-builder-model-1',
    id: process.env.NEXT_PUBLIC_ROOM_BUILDER_EXAMPLE_1,
  },
  {
    thumbnail: '/images/planner-thumb2.jpg',
    model: 'room-builder-model-2',
    id: process.env.NEXT_PUBLIC_ROOM_BUILDER_EXAMPLE_2,
  },
  {
    thumbnail: '/images/planner-thumb3.jpg',
    model: 'room-builder-model-3',
    id: process.env.NEXT_PUBLIC_ROOM_BUILDER_EXAMPLE_3,
  },
  {
    thumbnail: '/images/planner-thumb4.jpg',
    model: 'room-builder-model-4',
    id: process.env.NEXT_PUBLIC_ROOM_BUILDER_EXAMPLE_4,
  },
];
const DEFAULT_ROOM = ROOMS[0];

const POINTS = [
  { icon: DragIcon, pointKey: 'drag-and-drop' },
  { icon: FurnitureIcon, pointKey: 'furniture-catelog' },
  { icon: CustomizableIcon, pointKey: 'customizable-room' },
  { icon: WallIcon, pointKey: 'wall-and-floor' },
  { icon: VisualizationIcon, pointKey: '3d-visualization' },
  { icon: RealTimeIcon, pointKey: 'real-time' },
];

const CONTAINER_ID = 'room-builder-embedded-content';

export const RoomBuilderSection = () => {
  const { t } = useTranslation();
  const [selectedRoom, setSelectedRoom] = useState<RoomType>();
  const [isViewingQRModal, setIsViewingQRModal] = useState(false);
  const roomBuilderContainerRef = useRef<HTMLDivElement>();

  useScrollingDisabledElement(roomBuilderContainerRef);

  useEffect(() => {
    window.onRoomBuilderReady = () => {
      setSelectedRoom(DEFAULT_ROOM);
    };

    return () => {
      window.onRoomBuilderReady = undefined;
    };
  }, []);

  const open = useCallback(
    (room: RoomType) => () => {
      window.standalone3dapp.openSpaceConfiguration(room.id, {
        cameraAutoRotate: 0,
        cameraOrientation: 'default',
        isEditable: false,
      });
      setSelectedRoom(room);
    },
    []
  );

  return (
    <>
      <div className="mb-[74px] px-6 lg:mb-[144px] lg:px-0" id="room-builder">
        <FlexRow className="w-full flex-wrap justify-between">
          <h3 className="text-[26px] lg:text-[34px]">{t('landing:room-builder.title')}</h3>
          <Button3dStaged href="#contact" className="hidden lg:flex">
            {t('base:header.book-a-demo')}
          </Button3dStaged>
          <p className="mt-[30px] w-full leading-8">{t('landing:room-builder.description')}</p>
        </FlexRow>

        {/* RoomBuilder & gallery */}
        <div className="mt-10 flex w-full flex-col gap-y-3 gap-x-6 lg:mt-[50px] lg:h-[600px] lg:flex-row">
          {/* Gallery */}
          <div className="order-2 flex h-full justify-start gap-x-3 lg:order-1 lg:w-[130px] lg:flex-col lg:gap-y-[26px]">
            {ROOMS.map((room) => {
              const { id, thumbnail } = room;
              const isSelected = id === selectedRoom?.id;

              return (
                <Button
                  variant="unstyled"
                  className={twMerge(
                    'relative flex aspect-square h-auto w-[78px] grow items-center justify-center rounded-[25px] bg-white p-0 lg:h-[130px] lg:w-full lg:items-start',
                    !selectedRoom?.id ? 'pointer-events-none' : ''
                  )}
                  key={id}
                  onClick={open(room)}
                >
                  <ImageWithShimmer
                    src={thumbnail}
                    className="overflow-hidden rounded-[14px] lg:rounded-[25px]"
                    imgClassName="object-contain h-auto w-full"
                  />
                  <div
                    className={twMerge(
                      'border-primary absolute h-full w-full rounded-[14px] border-solid transition duration-150 lg:rounded-[25px]',
                      isSelected ? 'border-2' : ''
                    )}
                  />
                </Button>
              );
            })}
          </div>

          {/* RoomBuilder */}
          <div
            className="relative order-1 h-[418px] w-full overflow-hidden rounded-[40px] lg:order-2 lg:h-full lg:max-w-[1245px] [&>iframe]:rounded-[40px]"
            style={{ backgroundImage: 'linear-gradient(239deg, #C2BEB5 20.65%, rgba(194, 190, 181, 0.54) 75.4%)' }}
          >
            <div id={CONTAINER_ID} ref={roomBuilderContainerRef} className="h-full w-full" />
            {!!selectedRoom ? (
              <>
                {/* Desktop AR button */}
                <Button3dStaged
                  target="_blank"
                  variant="secondary"
                  className="border-shades-50 absolute left-8 bottom-8 hidden items-center leading-[20px] lg:flex"
                  onClick={() => setIsViewingQRModal(true)}
                >
                  <CubeIcon />
                  {t('landing:room-builder.view-this-room')}
                </Button3dStaged>
                <Button3dStaged
                  href={`/room-builder-editor/?id=${process.env.NEXT_PUBLIC_ROOM_BUILDER_DISTRIBUTION_ID}&spaceConfigurationId=${selectedRoom?.id}`}
                  target="_blank"
                  variant="secondary"
                  className="border-shades-50 absolute right-8 bottom-8 hidden items-center leading-[20px] lg:flex"
                >
                  <EditIcon />
                  {t('landing:room-builder.edit-this-room')}
                </Button3dStaged>
                {/* Mobile AR Button */}
                <Button3dStaged
                  href={`${MODEL_DETECTOR_URL}?model=${selectedRoom?.model}`}
                  target="_blank"
                  variant="secondary"
                  className=" border-shades-50 absolute bottom-[2%] left-[7%] h-12 w-fit items-center leading-[20px] lg:hidden"
                >
                  <CubeIcon />
                  {t('ui:ar')}
                </Button3dStaged>
              </>
            ) : null}
          </div>
        </div>

        {/* Points */}
        <div className="mt-10 flex flex-col flex-wrap gap-x-[4%] lg:mt-[45px] lg:flex-row lg:gap-y-[44px]">
          {POINTS.map(({ pointKey, icon: Icon }) => {
            return (
              <div key={pointKey} className="w-full lg:w-[30%] [&:not(:last-child)]:pb-9 lg:[&:not(:last-child)]:pb-0">
                <div className="mb-[24px] h-[30px]">
                  <Icon />
                </div>
                <h4 className="text-lg lg:text-[18px]">{t(`landing:room-builder.sub-sections.${pointKey}.title`)}</h4>
                <p className="leading-8">{t(`landing:room-builder.sub-sections.${pointKey}.description`)}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modals */}
      {isViewingQRModal ? (
        <QRCodeModal
          url={`${location.origin}${MODEL_DETECTOR_URL}/?model=${selectedRoom?.model}`}
          onClose={() => setIsViewingQRModal(false)}
        />
      ) : null}

      <Script
        defer={true}
        src={`
            ${process.env.NEXT_PUBLIC_ROOM_BUILDER_EMBED}?
              distributionId=${process.env.NEXT_PUBLIC_ROOM_BUILDER_DISTRIBUTION_ID}
              &mode=PRESET_ROOM_DOM
              &isHeaderDisabled=true
              &isSidePanelDisabled=true
              &defaultConfigurationId=${DEFAULT_ROOM.id}
              &defaultAutoRotate=0.1
              &defaultGraphicSettings=lowPerformance
              &defaultNoEdit=true
            `.replace(/\s/g, '')}
        type="text/javascript"
        id={`room-builder-embed-script-${Date.now()}`}
        dangerouslySetInnerHTML={{ __html: `room-builder-embed-script-${Date.now()}` }}
      />
    </>
  );
};
