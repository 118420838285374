import { css } from '@emotion/react';
import { useRef } from 'react';

import { useIsChartVisible } from './use-is-chart-visible';

export type PieChartStatProps = {
  containerClassNames: string;
};

export const PieChartStat = ({ containerClassNames }: PieChartStatProps) => {
  const containerRef = useRef();
  const { isVisible } = useIsChartVisible(containerRef);

  return (
    <div ref={containerRef} className={containerClassNames}>
      {isVisible ? (
        <img
          src="/images/graph-4.png"
          css={css`
            animation-name: pieChartStatAnime;
            animation-duration: 0.45s;
            animation-timing-function: ease-out;

            @keyframes pieChartStatAnime {
              0% {
                clip-path: polygon(45% 50%, 45% 0%, 45% 0%, 45% 0%, 45% 0%, 45% 0%, 45% 0%, 45% 0%, 45% 0%, 45% 0%);
              }
              12.5% {
                clip-path: polygon(
                  45% 50%,
                  45% 0%,
                  100% 0%,
                  100% 0%,
                  100% 0%,
                  100% 0%,
                  100% 0%,
                  100% 0%,
                  100% 0%,
                  100% 0%
                );
              }
              25% {
                clip-path: polygon(
                  45% 50%,
                  45% 0%,
                  100% 0%,
                  100% 50%,
                  100% 50%,
                  100% 50%,
                  100% 50%,
                  100% 50%,
                  100% 50%,
                  100% 50%
                );
              }
              37.5% {
                clip-path: polygon(
                  45% 50%,
                  45% 0%,
                  100% 0%,
                  100% 50%,
                  100% 100%,
                  100% 100%,
                  100% 100%,
                  100% 100%,
                  100% 100%,
                  100% 100%
                );
              }
              50% {
                clip-path: polygon(
                  45% 50%,
                  45% 0%,
                  100% 0%,
                  100% 50%,
                  100% 100%,
                  45% 100%,
                  45% 100%,
                  45% 100%,
                  45% 100%,
                  45% 100%
                );
              }
              62.5% {
                clip-path: polygon(
                  45% 50%,
                  45% 0%,
                  100% 0%,
                  100% 50%,
                  100% 100%,
                  45% 100%,
                  0% 100%,
                  0% 100%,
                  0% 100%,
                  0% 100%
                );
              }
              75% {
                clip-path: polygon(
                  45% 50%,
                  45% 0%,
                  100% 0%,
                  100% 50%,
                  100% 100%,
                  45% 100%,
                  0% 100%,
                  0% 50%,
                  0% 50%,
                  0% 50%
                );
              }
              100% {
                clip-path: polygon(
                  45% 50%,
                  45% 0%,
                  100% 0%,
                  100% 50%,
                  100% 100%,
                  45% 100%,
                  0% 100%,
                  0% 50%,
                  0% 0%,
                  45% 0%
                );
              }
            }
          `}
          alt="pie-graph"
        />
      ) : null}
    </div>
  );
};
