import useTranslation from 'next-translate/useTranslation';
import { twMerge } from 'tailwind-merge';

import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col';
import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row';
import { CheckedIcon } from '@unreserved-3d/ui/icons/checked-icon';
import { CheckedNoneIcon } from '@unreserved-3d/ui/icons/checked-none-icon';

const ROW_STYLES = 'my-4 h-[27px]';

enum RowTypes {
  ROOM_VISUALIZER = 'room-visualizer',
  VIEW_IN_ROOM_AR = 'view-in-room-ar',
  ROOM_PLANNER = 'room-planner',
  INSPIRATION_BASED_SHOPPING = 'inspiration-based-shopping',
  DEGREE_360_FURNITURE_SPIN = 'degree-360-furniture-spin',
  VISUAL_SEARCH = 'visual-search',
  CONFIGURATORS_3D = 'configurators-3d',
}

const COMPARABLES_STATS = [
  {
    logoClass: 'bg-[url(/images/logos/ikea.png)] bg-contain',
    checks: [
      RowTypes.ROOM_VISUALIZER,
      RowTypes.VIEW_IN_ROOM_AR,
      RowTypes.ROOM_PLANNER,
      RowTypes.INSPIRATION_BASED_SHOPPING,
      RowTypes.DEGREE_360_FURNITURE_SPIN,
      RowTypes.VISUAL_SEARCH,
      RowTypes.CONFIGURATORS_3D,
    ],
  },
  {
    logoClass: 'bg-[url(/images/logos/target.png)] bg-[length:50%]',
    checks: [
      RowTypes.ROOM_VISUALIZER,
      RowTypes.VIEW_IN_ROOM_AR,
      RowTypes.ROOM_PLANNER,
      RowTypes.DEGREE_360_FURNITURE_SPIN,
      RowTypes.CONFIGURATORS_3D,
    ],
  },
  {
    logoClass: 'bg-[url(/images/logos/amazon.png)] bg-contain',
    checks: [
      RowTypes.ROOM_VISUALIZER,
      RowTypes.VIEW_IN_ROOM_AR,
      RowTypes.INSPIRATION_BASED_SHOPPING,
      RowTypes.DEGREE_360_FURNITURE_SPIN,
      RowTypes.VISUAL_SEARCH,
    ],
  },
  {
    logoClass: 'bg-[url(/images/logos/walmart.png)] bg-[length:70%]',
    checks: [
      RowTypes.VIEW_IN_ROOM_AR,
      RowTypes.INSPIRATION_BASED_SHOPPING,
      RowTypes.DEGREE_360_FURNITURE_SPIN,
      RowTypes.VISUAL_SEARCH,
    ],
  },
  {
    logoClass: 'bg-[url(/images/logos/williams.png)] bg-contain',
    checks: [
      RowTypes.ROOM_PLANNER,
      RowTypes.INSPIRATION_BASED_SHOPPING,
      RowTypes.DEGREE_360_FURNITURE_SPIN,
      RowTypes.CONFIGURATORS_3D,
    ],
  },
  {
    logoClass: 'bg-[url(/images/logos/wayfair.png)] bg-[length:70%]',
    checks: [RowTypes.VIEW_IN_ROOM_AR, RowTypes.ROOM_PLANNER, RowTypes.CONFIGURATORS_3D],
  },
  {
    logoClass: 'bg-[url(/images/logos/overstock.png)] bg-contain',
    checks: [RowTypes.INSPIRATION_BASED_SHOPPING, RowTypes.CONFIGURATORS_3D],
  },
];

const ROWS = [
  RowTypes.ROOM_VISUALIZER,
  RowTypes.VIEW_IN_ROOM_AR,
  RowTypes.ROOM_PLANNER,
  RowTypes.INSPIRATION_BASED_SHOPPING,
  RowTypes.DEGREE_360_FURNITURE_SPIN,
  RowTypes.VISUAL_SEARCH,
  RowTypes.CONFIGURATORS_3D,
];

export const ComparablesSection = () => {
  const { t } = useTranslation();

  return (
    <FlexRow className="w-full flex-wrap px-6 pb-2 lg:h-[550px] lg:px-0 lg:pb-0">
      <FlexCol className="h-[50px] w-[150px] pr-[15px] lg:w-[360px] lg:pr-0">
        <div className="h-[87px] w-full flex-shrink-0 lg:h-[104px]" />
        {ROWS.map((titleKey) => {
          return (
            <span key={titleKey} className={twMerge('lg:text-[18px] lg:font-bold', ROW_STYLES)}>
              {t(`landing:comparables.${titleKey}`)}
            </span>
          );
        })}
      </FlexCol>
      <FlexRow className="flex-1 flex-nowrap overflow-x-auto">
        {COMPARABLES_STATS.map(({ logoClass, checks }, indx) => {
          return (
            <FlexCol
              className={twMerge('grow lg:basis-0', indx % 2 === 0 ? 'rounded-[25px] lg:bg-white' : '')}
              key={logoClass}
            >
              <div className={twMerge('h-[87px] w-full bg-center bg-no-repeat lg:h-[104px] lg:bg-auto', logoClass)} />
              <div
                className={twMerge(
                  'mx-auto flex w-[58px] flex-col items-center lg:w-fit',
                  indx % 2 === 0 ? 'rounded-[15px] bg-white lg:bg-transparent' : ''
                )}
              >
                {ROWS.map((rowKey) => {
                  return (
                    <span key={rowKey} className={twMerge(ROW_STYLES, 'flex w-[20px] justify-center lg:w-[27px]')}>
                      {checks.includes(rowKey) ? (
                        <CheckedIcon className="h-full w-full" />
                      ) : (
                        <CheckedNoneIcon className="h-full w-full" />
                      )}
                    </span>
                  );
                })}
              </div>
            </FlexCol>
          );
        })}
      </FlexRow>
    </FlexRow>
  );
};
