
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from 'next-translate/useTranslation';
import { NextPage } from 'next';
import NotificationWithAction from '@unreserved-frontend-v2/ui/notification-with-action';
import NotFoundImg from '@unreserved-frontend-v2/ui/page-not-found/page-not-found.svg';
import { PageTemplate } from '../components/pages/page-template';
export const Four04Page: NextPage = () => {
    const { t } = useTranslation();
    return (<PageTemplate>
      <NotificationWithAction imgSrc={NotFoundImg} imgAlt="404 Page Not Found" imgClassName="-ml-6" heading={t('ui:errors.headings.page-not-found')} message={t('ui:errors.messages.page-doesnt-exist')} className="py-10 text-center"/>
    </PageTemplate>);
};
export default Four04Page;

    async function __Next_Translate__getStaticProps__18ec8cf54ab__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18ec8cf54ab__ as getStaticProps }
  