import * as React from 'react';
import { SVGProps } from 'react';

export const DegreeViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" viewBox="0 0 30 30" {...props}>
    <path
      fill="#C7C4BC"
      d="M27.495 11.96C26.13 6.328 21.055 2.143 15 2.143A12.846 12.846 0 0 0 3.864 8.57H7.5c.59 0 1.071.483 1.071 1.072 0 .59-.482 1.071-1.071 1.071H1.071C.482 10.714 0 10.232 0 9.643V3.214c0-.589.482-1.071 1.071-1.071.59 0 1.072.482 1.072 1.071v4.058C4.768 2.912 9.543 0 15 0c7.125 0 13.091 4.969 14.618 11.625.148.643-.361 1.232-1.024 1.232-.53 0-.978-.382-1.105-.897h.006Zm-24.99 6.08C3.87 23.672 8.945 27.857 15 27.857c4.761 0 8.913-2.585 11.136-6.428h-3.65c-.589 0-1.07-.483-1.07-1.072 0-.59.481-1.071 1.07-1.071h6.429c.59 0 1.072.482 1.072 1.071v6.429c0 .589-.482 1.071-1.072 1.071-.59 0-1.071-.482-1.071-1.071v-4.032A15 15 0 0 1 15 30C7.875 30 1.908 25.031.382 18.375c-.148-.643.361-1.232 1.024-1.232.53 0 .978.382 1.105.897h-.007Z"
    />
  </svg>
);
