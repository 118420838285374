import * as React from 'react';
import { SVGProps } from 'react';

export const MouseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={22} viewBox="0 0 17 22" fill="none" {...props}>
    <path
      fill="url(#a)"
      d="M6.875 0A6.873 6.873 0 0 0 0 6.875v8.25A6.873 6.873 0 0 0 6.875 22h2.75a6.873 6.873 0 0 0 6.875-6.875v-8.25A6.873 6.873 0 0 0 9.625 0h-2.75Zm2.75 5.5v1.375c0 .76-.614 1.375-1.375 1.375-.76 0-1.375-.614-1.375-1.375V5.5c0-.76.614-1.375 1.375-1.375.76 0 1.375.614 1.375 1.375Z"
    />
    <defs>
      <linearGradient id="a" x1={8.25} x2={8.25} y1={0} y2={22} gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1AF1E" />
        <stop offset={1} stopColor="#CF9311" />
      </linearGradient>
    </defs>
  </svg>
);
