import { SVGProps } from 'react'

export const CircleClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Zm6.836-1.871 1.84 1.836-1.84 1.87c-.363.368-.363.962 0 1.294.367.398.96.398 1.293 0l1.836-1.805 1.87 1.805c.368.398.962.398 1.294 0 .398-.332.398-.926 0-1.293l-1.805-1.871 1.805-1.836c.398-.332.398-.926 0-1.293-.332-.363-.926-.363-1.293 0l-1.871 1.84-1.836-1.84c-.332-.363-.926-.363-1.293 0-.363.367-.363.96 0 1.293Z"
      fill="currentColor"
    />
  </svg>
)

export default CircleClose
