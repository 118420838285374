import * as React from 'react';
import { SVGProps } from 'react';

export const HandPayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={23} viewBox="0 0 25 23" fill="none" {...props}>
    <path
      fill="url(#a)"
      d="M5.558 6.25a6.25 6.25 0 1 1 12.5 0 6.25 6.25 0 0 1-12.5 0ZM10.8 4.835a.494.494 0 0 1 .182-.147c.221-.118.542-.178.812-.174.355.004.742.078 1.146.178a.695.695 0 1 0 .334-1.35 9.062 9.062 0 0 0-.777-.16v-.404a.696.696 0 0 0-.695-.695.696.696 0 0 0-.694.695v.412a2.748 2.748 0 0 0-.781.274c-.513.273-.999.798-.947 1.614.044.695.508 1.098.938 1.333.382.204.855.338 1.241.447l.078.021c.447.126.777.226 1.007.36.196.118.204.183.204.244a.32.32 0 0 1-.043.195.407.407 0 0 1-.174.143c-.204.109-.512.165-.803.157-.412-.013-.803-.135-1.297-.296a18.044 18.044 0 0 0-.252-.078.695.695 0 0 0-.868.456.695.695 0 0 0 .456.868c.069.022.143.043.216.07.309.1.656.212 1.03.286v.495c0 .382.312.694.694.694a.696.696 0 0 0 .694-.694v-.46c.27-.048.543-.135.794-.27.526-.282.968-.811.942-1.601-.021-.703-.447-1.142-.89-1.402-.408-.243-.92-.386-1.323-.5h-.009c-.451-.125-.794-.225-1.037-.355-.209-.113-.209-.174-.209-.195V4.99c-.004-.082.013-.125.035-.156h-.004Zm13.863 9.762a1.734 1.734 0 0 1-.37 2.426l-5.494 4.05a5.908 5.908 0 0 1-3.503 1.15H1.391c-.768 0-1.389-.621-1.389-1.39v-2.777c0-.768.62-1.39 1.389-1.39h1.597l1.949-1.562a5.545 5.545 0 0 1 3.472-1.215h6.871c.768 0 1.389.62 1.389 1.389 0 .768-.62 1.389-1.39 1.389h-3.471a.697.697 0 0 0-.695.694c0 .382.313.695.695.695h5.234l5.195-3.828a1.734 1.734 0 0 1 2.427.369Zm-16.26 2.07h-.038.039Z"
    />
    <defs>
      <linearGradient id="a" x1={12.502} x2={12.502} y1={0} y2={22.223} gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1AF1E" />
        <stop offset={1} stopColor="#CF9311" />
      </linearGradient>
    </defs>
  </svg>
);
