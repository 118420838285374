import { faLoader } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from 'tailwind-merge'

export interface SpinnerProps {
  size?: number
  className?: string
}

export function Spinner({ size = 22, className = '' }: SpinnerProps) {
  return <FontAwesomeIcon icon={faLoader} transform={{ size }} className={twMerge('animate-spin', className)} />
}

export default Spinner
