import { twMerge } from 'tailwind-merge'

export interface SpacerProps {
  className?: string
}

export function Spacer({ className = '' }: SpacerProps) {
  return <div className={twMerge('flex-grow', className)}></div>
}

export default Spacer
