import * as React from 'react';
import { SVGProps } from 'react';

export const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={6} height={9} viewBox="0 0 6 9" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M5.23 4.718v-.03a.947.947 0 0 0-.277-.701L1.929.965a.947.947 0 0 0-1.34 1.34l2.384 2.383L.59 7.071a.947.947 0 1 0 1.34 1.34l3.022-3.022a.948.948 0 0 0 .277-.67Z"
    />
  </svg>
);
