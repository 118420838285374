import useTranslation from 'next-translate/useTranslation';
import { twMerge } from 'tailwind-merge';
import { FormEvent, HTMLInputTypeAttribute, useCallback, useEffect, useRef, useState } from 'react';

import Spinner from '@unreserved-frontend-v2/ui/spinner';
import CircleCheckmarkIcon from '@unreserved-frontend-v2/ui/icons/circle-checkmark';
import { Button3dStaged } from '@unreserved-3d/ui/button-3d-staged/button-3d-staged';
import { CNIcon } from '@unreserved-3d/ui/icons/cn-icon';
import { useCreateFurnitureGeneralInquiry } from '@unreserved-3d/api/hooks/use-create-general-furniture-inquiry';
import { mapMutationErrorsForUI } from 'unreserved-frontend-v2/libs/modules/src/lib/api-mappings/errors';
import { SchemaFormError } from '@unreserved-frontend-v2/ui/schema-form/models';
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col';
import { CreateFurnitureGeneralInquiryInput } from '@unreserved-3d/api/generated/graphql/types';
import SchemaFormErrorContainer from '@unreserved-frontend-v2/ui/schema-form/schema-form-error-container';
import { isMobile } from '@unreserved-frontend-v2/utils/mobile';

const BASE_INPUT_STYLES =
  'rounded-[10px] lg:flex-[0_47.5%] flex-[0_100%] border border-solid border-shades-10 h-[54px] leading-[50px] placeholder:text-shades-5 mb-6 indent-5 ';
const ERROR_STYLES = 'border-red-800 bg-red-50 text-red-600 placeholder:text-red-200';

const formInputs: {
  placeholderKey: string;
  fieldId: keyof CreateFurnitureGeneralInquiryInput;
  type?: HTMLInputTypeAttribute | 'TextArea';
}[] = [
  {
    fieldId: 'firstName',
    placeholderKey: 'landing:contact.form.first-name',
  },
  {
    fieldId: 'lastName',
    placeholderKey: 'landing:contact.form.last-name',
  },
  {
    fieldId: 'email',
    placeholderKey: 'landing:contact.form.email',
  },
  {
    type: 'number',
    fieldId: 'phoneNumber',
    placeholderKey: 'landing:contact.form.phone-number',
  },
  {
    fieldId: 'company',
    placeholderKey: 'landing:contact.form.company',
  },
  {
    fieldId: 'website',
    placeholderKey: 'landing:contact.form.website',
  },
  {
    fieldId: 'message',
    type: 'TextArea',
    placeholderKey: 'landing:contact.form.notes',
  },
  {
    fieldId: 'newsletter',
    type: 'checkbox',
    placeholderKey: 'landing:contact.form.subscribe',
  },
];

export const ContactSection = () => {
  const { t } = useTranslation();
  const { mutate, isSuccess, isError, error } = useCreateFurnitureGeneralInquiry();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [uiErrors, setUiErrors] = useState<SchemaFormError[]>([]);
  const formContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isError) {
      setSubmitted(false);
      setUiErrors(mapMutationErrorsForUI(error));

      if (isMobile()) {
        document.scrollingElement.scrollTop = formContainerRef.current?.offsetTop;
      }
    }

    if (isSuccess) {
      setSubmitted(false);
      setUiErrors([]);

      // TODO: Analytics support
      // pushAnalyticsEvent('generate_lead');
      // pushFBEvent('Lead');
    }
  }, [isError, isSuccess, error]);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setSubmitted(true);

      const formData = formInputs.reduce((acc, { fieldId, type }) => {
        const target = document.querySelector(`#contact #${fieldId}`) as HTMLInputElement | HTMLTextAreaElement;
        acc[fieldId] = type === 'checkbox' ? !!(target as HTMLInputElement)?.checked : target?.value || '';

        return acc;
      }, {});

      try {
        mutate(formData as CreateFurnitureGeneralInquiryInput);
      } catch (e) {
        console.log('error occured when trying to submit inquiry', e);
      }
    },
    [mutate]
  );

  return (
    <div className="flex flex-col px-6 pt-[20px] lg:flex-row lg:px-0 lg:pt-0" id="contact">
      <div className="relative lg:h-auto min-h-[404px] lg:min-h-[560px] w-full flex-shrink-0 rounded-t-[24px] bg-[url(/images/map.jpg)] bg-cover bg-center lg:w-1/2 lg:rounded-t-none">
        <Button3dStaged
          href="https://maps.app.goo.gl/qYABQ8fTEkwwtbZ1A"
          target="_blank"
          className="bg-shades-1000 absolute right-8 bottom-8 left-8 h-[76px] w-auto p-0 lg:h-[50px]"
        >
          <CNIcon className="mr-[15px] mb-1 ml-[20px]" />
          <span className="flex-1 text-left text-white">{t('landing:contact.address')}</span>
          <i className="mr-[25px] mb-0.5 -rotate-45 border-r-2 border-b-2 border-solid border-white p-[3px]" />
        </Button3dStaged>
      </div>
      <div
        ref={formContainerRef}
        className="h-auto relative w-full rounded-b-[24px] bg-white lg:w-1/2 lg:rounded-b-none"
      >
        <h4 className="pt-[32px] pl-6 text-[18px] lg:pl-[32px]">{t('landing:contact.contact-us')}</h4>
        <SchemaFormErrorContainer
          errors={uiErrors}
          className="relative lg:ml-[5%] ml-6 w-[calc(100%-48px)] lg:w-[90%]"
          showErrors={!!uiErrors.length}
        />
        {isSuccess ? (
          <div data-testid="contact-us-success" className="h-auto pb-20 pt-10 lg:top-[150px] lg:py-0 relative">
            <FlexCol className="items-center justify-center pb-[21px] text-primary">
              <CircleCheckmarkIcon />
            </FlexCol>
            <p className="flex items-center justify-center text-center">{t('landing:contact.form.success-message')}</p>
          </div>
        ) : (
          <form
            className={twMerge(
              'relative mt-8 flex w-full flex-wrap justify-between px-6 lg:mx-0 lg:ml-[5%] lg:w-[90%] lg:px-0 lg:pb-[34px]',
              submitted ? 'pointer-events-none opacity-50' : ''
            )}
            onSubmit={onSubmit}
          >
            {/* Fields */}
            {formInputs.map(({ type = 'text', fieldId, placeholderKey }) => {
              const isErrored = uiErrors.some(({ invalidField }) => invalidField === fieldId);

              if (type === 'TextArea') {
                return (
                  <textarea
                    key={fieldId}
                    id={fieldId}
                    placeholder={t(placeholderKey)}
                    className={twMerge(BASE_INPUT_STYLES, 'h-[136px] lg:w-full lg:flex-[0_100%]')}
                  />
                );
              }

              return type === 'checkbox' ? (
                <label
                  key={fieldId}
                  className="text-shades-5 relative mb-6 block flex-[0_100%] cursor-pointer select-none pl-[45px] leading-7 lg:mb-0 lg:mt-[12px] lg:flex-auto"
                >
                  {t(placeholderKey)}
                  <input
                    id={fieldId}
                    type="checkbox"
                    className={twMerge(
                      'border-shades-10 peer absolute float-left mb-6 h-[50px] cursor-pointer opacity-0',
                      isErrored ? ERROR_STYLES : ''
                    )}
                  />
                  <span className="peer-checked:bg-shades-900 border-shades-10 absolute top-0 left-0 h-[26px] w-[26px] rounded-[6px] border border-solid" />
                </label>
              ) : (
                <input
                  key={fieldId}
                  type={type}
                  id={fieldId}
                  name={fieldId}
                  placeholder={t(placeholderKey)}
                  className={twMerge(BASE_INPUT_STYLES, isErrored ? ERROR_STYLES : '')}
                />
              );
            })}
            {/* Buttons */}
            <Button3dStaged
              type="submit"
              className="flex-0 mb-[22px] w-full justify-center px-[30px] py-0 lg:absolute lg:bottom-[21px] lg:right-0 lg:mb-0 lg:w-fit"
            >
              {submitted ? <Spinner /> : t('landing:contact.form.send')}
            </Button3dStaged>
          </form>
        )}
      </div>
    </div>
  );
};
