/**
 * Scroll restoration is currently a known nextJS issue: https://github.com/vercel/next.js/issues/20951
 *
 * This hook is a solution from the community: https://gist.github.com/claus/992a5596d6532ac91b24abe24e10ae81
 *
 * */

import { useEffect } from 'react'

import Router, { Router as RouterInstance } from 'next/router'

export function saveScrollPos(url: string) {
  const scrollPos = { x: window.scrollX, y: window.scrollY }

  try {
    sessionStorage.setItem(url, JSON.stringify(scrollPos))
  } catch (e) {
    // Just ignore the error as can't do much to recover.
  }
}

export function restoreScrollPos(url: string) {
  try {
    const scrollPos = JSON.parse(sessionStorage.getItem(url) as string)
    if (scrollPos) {
      window.scrollTo(scrollPos.x, scrollPos.y)
    }
  } catch (e) {
    // Just ignore the error as can't do much to recover.
  }
}

export function clearStoredScrollPos(url: string) {
  try {
    const hadScrollPosition = !!sessionStorage.getItem(url)
    sessionStorage.removeItem(url)

    return hadScrollPosition
  } catch (e) {
    // Just ignore the error as can't do much to recover.
    return false
  }
}

export const useScrollRestoration = (router: RouterInstance) => {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      let shouldScrollRestore = false
      window.history.scrollRestoration = 'manual'
      restoreScrollPos(router.asPath)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onBeforeUnload = (event: any) => {
        saveScrollPos(router.asPath)
        delete event['returnValue']
      }

      const onRouteChangeStart = () => {
        saveScrollPos(router.asPath)
      }

      const onRouteChangeComplete = (url: string) => {
        if (shouldScrollRestore) {
          shouldScrollRestore = false
          restoreScrollPos(url)
        }
      }

      window.addEventListener('beforeunload', onBeforeUnload)
      Router.events.on('routeChangeStart', onRouteChangeStart)
      Router.events.on('routeChangeComplete', onRouteChangeComplete)
      Router.beforePopState(() => {
        shouldScrollRestore = true
        return true
      })

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload)
        Router.events.off('routeChangeStart', onRouteChangeStart)
        Router.events.off('routeChangeComplete', onRouteChangeComplete)
        Router.beforePopState(() => true)
      }
    }

    return undefined
  }, [router])
}
