
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@fortawesome/fontawesome-svg-core/styles.css';
import Head from 'next/head';
import './styles.css';
import useTranslation from 'next-translate/useTranslation';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { useState, useEffect } from 'react';
import { AppProps } from 'next/app';
import { Poppins } from 'next/font/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { config } from '@fortawesome/fontawesome-svg-core';
import { ClientError } from 'graphql-request';
import Four04Page from './404';
import { PageTemplate } from '../components/pages/page-template';
import { useScrollRestoration } from '@unreserved-frontend-v2/ui/hooks/use-scroll-resortation/use-scroll-restoration';
import { createEmotionCache } from '@unreserved-frontend-v2/ui/utils/create-emotion-cache';
import { ErrorBoundary } from '../components/pages/error-boundary/error-boundary';
import { getErrorCode } from '@unreserved-3d/api/fetchers/utils';
const customFont = Poppins({
    subsets: ['latin'],
    weight: ['400', '600', '700', '800'],
    adjustFontFallback: true
});
// Force build: 001
config.autoAddCss = false;
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
function CustomApp({ Component, pageProps, router, emotionCache = clientSideEmotionCache, }: AppProps<{
    errorCode?: number;
    dehydratedState: unknown;
}> & {
    emotionCache?: EmotionCache;
}) {
    const [errorCode, setErrorCode] = useState<number | undefined>(pageProps.errorCode);
    const { t } = useTranslation();
    useEffect(() => {
        /**
         * When the pageProps change it means that we are on a new page. We set the ErrorCode here based on
         * the new props. If there isn't an error on the new page, errorCode will be undefined and this effectively
         * sets errorCode back to its default state (e.g. - no error) and the new page will render as normal.
         */
        setErrorCode(pageProps.errorCode);
    }, [pageProps]);
    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 60000, retry: 0 } }
    }));
    useScrollRestoration(router);
    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 5000,
                retry: 0,
                onError: (e: unknown) => {
                    setErrorCode(getErrorCode(e as ClientError));
                }
            },
            mutations: {
                retry: 0,
                onError: (e: unknown) => {
                    setErrorCode(getErrorCode(e as ClientError));
                }
            }
        });
    }, [queryClient]);
    return (<>
      <style jsx={true} global={true}>
        {`
          :root {
            --font-poppins: ${customFont.style.fontFamily};
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width, maximum-scale=1"/>
            <title>{t('base:metadata.title')}</title>
            <meta name="description" content={t('base:metadata.description')}/>
            {/*
         * Disabling auto-phone detection because it causes hydration errors on mobile, use
         * `tel:XXX-XXX` links instead intentionally
         *
         * https://stackoverflow.com/a/227238
         */}
            <meta name="format-detection" content="telephone=no"/>
          </Head>
          <main className="app">
            <ErrorBoundary>
              {errorCode ? (<PageTemplate>
                  <Four04Page />
                </PageTemplate>) : (<Component {...pageProps}/>)}
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false}/>
          </main>
        </CacheProvider>
      </QueryClientProvider>
    </>);
}
const __Next_Translate__Page__18ec8cf549c__ = CustomApp;

    export default __appWithI18n(__Next_Translate__Page__18ec8cf549c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  