import { useLayoutEffect, useMemo, useState } from 'react';

export const useIsChartVisible = (containerRef: React.MutableRefObject<undefined | HTMLElement>) => {
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    if (!containerRef?.current) {
      return;
    }

    // Observer for when an item is in view
    const observer = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.intersectionRatio)) {
        setIsVisible(true);
      }
    });

    // Only observe/allow animation after scrolled
    const onScroll = () => {
      observer.observe(containerRef?.current);
      document.removeEventListener('scroll', onScroll);
    };

    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [containerRef]);

  return useMemo(() => {
    return { isVisible };
  }, [isVisible]);
};
