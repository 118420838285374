import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import _uniqueId from 'lodash/uniqueId'

interface SkeletonProps {
  className?: string
}

export function Skeleton({ className }: SkeletonProps) {
  const [id] = useState(_uniqueId('skele-'))
  return (
    <svg
      className={twMerge(`h-full w-full ${className ?? ''}`)}
      width="100%"
      height="100%"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect id={id} width="100%" height="100%" fill="#eee" />
      <animate
        xlinkHref={`#${id}`}
        attributeName="opacity"
        values="0.4;1;0.4"
        dur="1.5s"
        repeatCount="indefinite"
        begin="1s"
      />
    </svg>
  )
}

export default Skeleton
