import { css } from '@emotion/react';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { useIsChartVisible } from './use-is-chart-visible';

export type CircleStatProps = {
  containerClassNames: string;
};

export const CircleStat = ({ containerClassNames }: CircleStatProps) => {
  const containerRef = useRef();
  const { isVisible } = useIsChartVisible(containerRef);

  return (
    <div ref={containerRef} className={twMerge('relative', containerClassNames)}>
      {isVisible ? (
        <div className="relative w-[120px] h-[118px]">
          <div
            css={css`
              @property --p {
                syntax: '<number>';
                inherits: true;
                initial-value: 0;
              }

              --p: 66;
              --thickness: 30px;
              --color: #43aa8b;
              --width: 120px;

              width: var(--width);
              aspect-ratio: 1;
              position: relative;
              display: inline-grid;
              place-content: center;
              animation: pie 0.5s 0.5s both;

              &:before,
              &:after,
              .head {
                content: '';
                position: absolute;
                border-radius: 50%;
              }

              &:before {
                inset: 0;
                background: conic-gradient(#d3e3db 100%, #0000 0);
                -webkit-mask: radial-gradient(
                  farthest-side,
                  #0000 calc(99% - var(--thickness)),
                  #000 calc(100% - var(--thickness))
                );
                mask: radial-gradient(
                  farthest-side,
                  #0000 calc(99% - var(--thickness)),
                  #000 calc(100% - var(--thickness))
                );
              }
              &:after {
                inset: 0;
                background: radial-gradient(farthest-side, var(--color) 98%, #0000) top/var(--thickness)
                    var(--thickness) no-repeat,
                  conic-gradient(var(--color) calc(var(--p) * 1%), #0000 0);
                -webkit-mask: radial-gradient(
                  farthest-side,
                  #0000 calc(99% - var(--thickness)),
                  #000 calc(100% - var(--thickness))
                );
                mask: radial-gradient(
                  farthest-side,
                  #0000 calc(99% - var(--thickness)),
                  #000 calc(100% - var(--thickness))
                );
              }

              .head {
                z-index: 1;
                inset: calc(50% - var(--thickness) / 2);
                background: var(--color);
                animation: pieHead 0.5s 0.5s both;
              }

              @keyframes pie {
                from {
                  --p: 0;
                }
                0% {
                  opacity: 0;
                }
                25% {
                  opacity: 1;
                }
              }

              @keyframes pieHead {
                from {
                  transform: rotate(calc(0 * 3.6deg)) translateY(calc(50% - var(--width) / 2));
                }
                to {
                  transform: rotate(calc(66 * 3.6deg)) translateY(calc(50% - var(--width) / 2));
                }
              }
            `}
          >
            <div className="head" />
          </div>
          <div
            className="label text-[9px] font-bold top-[70px] left-[14px] text-white"
            css={css`
              position: absolute;
              animation: pieLabel 0.5s 0.5s both;
              animation-delay: 0.5s;

              @keyframes pieLabel {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
            `}
          >
            66%
          </div>
        </div>
      ) : null}
    </div>
  );
};
