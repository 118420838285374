import { twMerge } from 'tailwind-merge'

export interface FlexColProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  id?: string
  span?: number
  className?: string
  dataTestId?: string
  children: React.ReactNode
}

export function FlexCol({ span, className = '', dataTestId = '', children, ...rest }: FlexColProps) {
  return (
    <div
      className={twMerge('flex flex-col', className, span ? `col-span-${span}` : '')}
      data-testid={dataTestId}
      {...rest}
    >
      {children || null}
    </div>
  )
}
