import * as React from 'react';
import { SVGProps } from 'react';

export const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width={21} height={21} fill="none" {...props}>
    <path
      fill="#1E1E1E"
      d="m18.653 1.851.496.497a1.315 1.315 0 0 1 0 1.858l-1.095 1.096-2.355-2.355 1.095-1.096a1.315 1.315 0 0 1 1.859 0Zm-10.898 9.04 7.016-7.017 2.355 2.355-7.016 7.016a1.345 1.345 0 0 1-.632.353l-2.691.62.62-2.696c.052-.238.176-.46.352-.632h-.004ZM15.867.92l-9.04 9.043c-.348.349-.59.788-.701 1.268l-.858 3.717a.66.66 0 0 0 .788.788l3.718-.857c.48-.111.919-.353 1.268-.702l9.039-9.043a2.625 2.625 0 0 0 0-3.714l-.497-.5a2.625 2.625 0 0 0-3.713 0h-.004ZM3.282 2.618A3.282 3.282 0 0 0 0 5.901v11.817A3.282 3.282 0 0 0 3.283 21H15.1a3.282 3.282 0 0 0 3.282-3.282v-5.252a.658.658 0 0 0-.657-.657.658.658 0 0 0-.656.657v5.252a1.97 1.97 0 0 1-1.97 1.97H3.284a1.97 1.97 0 0 1-1.97-1.97V5.9a1.97 1.97 0 0 1 1.97-1.97h5.251a.658.658 0 0 0 .657-.656.658.658 0 0 0-.657-.657H3.282Z"
    />
  </svg>
);
