import useTranslation from 'next-translate/useTranslation';
import { twMerge } from 'tailwind-merge';

export const FAQSection = () => {
  const { t } = useTranslation();

  return (
    <div id="faq" className="px-6 lg:px-0">
      <h3 className="margin-auto mb-[39px] text-center text-[26px] lg:hidden">{t('landing:faq.title')}</h3>
      <ul className="list-none">
        {Array.from({ length: 5 }).map((_, indx) => {
          return (
            <li key={`point-${indx}`} className="bg-shades-45 relative mb-[20px] rounded-[25px]">
              <input
                type="checkbox"
                defaultChecked={true}
                className="peer absolute z-[1] h-full w-full cursor-pointer opacity-0"
              />

              <i className="absolute right-0 top-4 translate-x-[-6px] peer-checked:[&>:first-child]:translate-x-[2px] peer-checked:[&>:last-child]:translate-x-[-2px]">
                <span
                  className={twMerge(
                    'bg-shades-900 absolute right-[40px] top-[18px] h-2 w-[3px] transition-all duration-[0.25s] ease-in-out',
                    'translate-x-[-2px] rotate-45'
                  )}
                />
                <span
                  className={twMerge(
                    'bg-shades-900 absolute right-[40px] top-[18px] h-2 w-[3px] transition-all duration-[0.25s] ease-in-out',
                    'translate-x-[2px] -rotate-45'
                  )}
                />
              </i>
              <h4 className="mb-0 cursor-pointer px-[40px] pr-[60px] pt-[25px] text-[16px] lg:pr-0 lg:text-[18px]">
                {t(`landing:faq.sub-sections.${indx}.title`)}
              </h4>
              <p className="mx-[40px] mt-[15px] max-h-[800px] pb-[25px] leading-8 transition-all duration-[0.25s] ease-in-out peer-checked:mt-0 peer-checked:max-h-0  peer-checked:opacity-0">
                {t(`landing:faq.sub-sections.${indx}.description`)}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
