import * as React from 'react';
import { SVGProps } from 'react';

export const CubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={25} viewBox="0 0 21 25" fill="none" {...props}>
    <path
      fill="#1E1E1E"
      fillRule="evenodd"
      d="M10.063.927a.802.802 0 0 1 .407-.115h.008c.154 0 .298.043.42.118l2.751 1.604a.806.806 0 0 1-.813 1.393l-1.552-.905V5.65a.806.806 0 0 1-1.612 0V3.022l-1.552.905a.806.806 0 0 1-.813-1.393L10.063.927Zm-3.046 21.25a.806.806 0 0 1 1.103-.291l1.552.905v-2.627a.806.806 0 1 1 1.612 0v2.627l1.552-.905a.806.806 0 1 1 .813 1.393l-2.738 1.597a.803.803 0 0 1-.864.001l-2.74-1.598a.806.806 0 0 1-.29-1.103ZM4.175 5.263c.227.383.1.877-.283 1.104l-1.51.895 2.224 1.322a.806.806 0 0 1-.824 1.386L1.609 8.68v1.783a.806.806 0 0 1-1.613 0V7.282a.803.803 0 0 1 .42-.728L3.07 4.982a.806.806 0 0 1 1.105.283Zm15.979 9.28c.445 0 .806.361.806.806v3.163a.802.802 0 0 1-.42.747l-2.653 1.572a.806.806 0 0 1-.823-1.387l1.51-.895-2.222-1.322a.806.806 0 0 1 .824-1.386l2.172 1.291V15.35c0-.445.36-.806.806-.806Zm-19.351 0c.445 0 .806.361.806.806v1.777l2.137-1.283a.806.806 0 0 1 .83 1.383l-2.208 1.325 1.53.92a.806.806 0 1 1-.833 1.382L.404 19.252a.803.803 0 0 1-.408-.72V15.35c0-.445.361-.806.807-.806Zm15.979-9.28a.806.806 0 0 1 1.105-.283l2.647 1.57a.803.803 0 0 1 .426.75v3.162a.806.806 0 0 1-1.612 0V8.68l-2.172 1.291a.806.806 0 0 1-.824-1.386l2.222-1.321-1.51-.896a.806.806 0 0 1-.282-1.104Zm-2.843 5.623a.806.806 0 0 1-.29 1.103l-2.364 1.38v2.762a.806.806 0 0 1-1.613 0v-2.763L7.307 11.99a.806.806 0 0 1 .813-1.393l2.358 1.376 2.358-1.376a.806.806 0 0 1 1.103.29Z"
      clipRule="evenodd"
    />
  </svg>
);
