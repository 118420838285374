import * as React from 'react';
import { SVGProps } from 'react';

export const CustomizableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" viewBox="0 0 28 28" {...props}>
    <path
      fill="#C7C4BC"
      d="M1.75 2.477V24.5c0 .968.782 1.75 1.75 1.75h22.023a.727.727 0 0 0 .514-1.241l-2.155-2.155-1.389 1.389a.878.878 0 0 1-1.236 0 .878.878 0 0 1 0-1.236l1.39-1.389-3.14-3.14-1.389 1.39a.878.878 0 0 1-1.236 0 .878.878 0 0 1 0-1.236l1.39-1.389-3.14-3.14-1.389 1.39a.878.878 0 0 1-1.236 0 .878.878 0 0 1 0-1.236l1.39-1.389-3.14-3.14-1.389 1.39a.878.878 0 0 1-1.236 0 .878.878 0 0 1 0-1.236l1.39-1.389-3.14-3.14-1.389 1.39a.878.878 0 0 1-1.236 0 .878.878 0 0 1 0-1.236l1.39-1.389-2.161-2.155a.722.722 0 0 0-1.236.514ZM4.227.727l23.046 23.046A2.476 2.476 0 0 1 25.523 28H3.5C1.57 28 0 26.43 0 24.5V2.477A2.476 2.476 0 0 1 4.227.727Zm2.44 12.47a.866.866 0 0 1 .951.19l7 7a.88.88 0 0 1 .191.952.878.878 0 0 1-.809.542H7a.878.878 0 0 1-.875-.875V14c0-.355.213-.673.541-.81v.006Zm1.208 2.914v4.014h4.014l-4.014-4.014Z"
    />
  </svg>
);
