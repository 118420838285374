
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { Landing } from '../components/pages/landing/landing';
export class App extends React.Component {
    render(): React.ReactNode {
        return <Landing />;
    }
}
export default App;

    async function __Next_Translate__getStaticProps__18ec8cf54a2__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18ec8cf54a2__ as getStaticProps }
  