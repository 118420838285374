module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['ui', 'base'],
    '/': ['landing'],
    '/presentation/sunpan': ['presentation'],
    '/presentation/dynasty': ['presentation'],
    '/presentation/infinite': ['presentation'],
    '/presentation/candace-and-basil': ['presentation'],
  },

  loadLocaleFrom: (lang, ns) =>
    import(`./locales/${lang}/${ns}.json`)
      .then((m) => m.default)
      .catch(() => import(`./locales/${lang}/${ns}.json`).then((m) => m.default)),
};
